export enum WebSocketEvent {
  MESSAGE_CREATED = "comment.created",
  BUG_UPDATED = "bug.updated",
  BUG_CREATED = "bug.created",
  BUG_TYPING = "bug.typing",
  BOT_STARTFEEDBACKFLOW = "bot.startfeedbackflow",
  BOT_OPENLINK = "bot.openlink",
  BOT_UPDATECURRENTACTION = "bot.updatedcurrentaction",
  BOT_WRITTING = "bot.writting",
  BOT_TOOL = "bot.tool",
  CHECKLIST_UPDATED = "checklist.updated",
  CUSTOMACTION_RUN = "customaction.run",
}

export enum WebSocketDataContentType {
  MESSAGE = "MESSAGE",
  TICKET = "TICKET",
  TYPING = "TYPING",
  BOT = "BOT",
  CHECKLIST = "CHECKLIST",
}

export type WebSocketEventData = {
  contentType: WebSocketDataContentType;
  action: WebSocketEvent;
  actionID: string;
  parentID: string;
  actionData?: any;
};

export type PreparedWebSocketEventData = {
  contentType: WebSocketDataContentType;
  action: WebSocketEvent;
  actionID: string;
  parentID: string;
};
