import { useState } from "react";
import { ReactComponent as CaretUpIcon } from "../../Assets/caret-up.svg";
import { ReactComponent as ChevronRightIcon } from "../../Assets/angle-right-solid.svg";
import { ReactComponent as PlusIcon } from "../../Assets/plus-solid.svg";
import { ReactComponent as CheckIcon } from "../../Assets/check-solid.svg";
import { useConfigStore } from "../../Stores/Config";
import { useFeatureRequestStore } from "../../Stores/FeatureRequests";
import { useFormStore } from '../../Stores/Form';
import "./FeatureRequestSuggestions.scss";
import CommunicationManager from "../../Helper/CommunicationManager";
import { useRouterStore } from "../../Stores/Router";

export const suggestionTitle = "We found existing feature requests that might align with your idea. Upvote the ones you like or go ahead and submit your own idea.";

function FeatureRequestSuggestions() {
    const formStore = useFormStore();
    const featureRequestStore = useFeatureRequestStore();
    const [openedApp, setOpenedApp] = useState(false);

    return (
        <div className='form-duplicates'>
            {formStore.duplicates?.map((item: any, index: number) => {
                const upvotes = item.upvotesCount ?? 0;
                return (<div key={`duplicate-${index}`} className='form-duplicate' onClick={() => {
                    featureRequestStore.openFeatureRequest(item.shareToken, true);

                    setTimeout(() => {
                        setOpenedApp(true);
                    }, 1500);
                }}>
                    <div className='form-duplicate__upvotes'>
                        <CaretUpIcon />
                        {upvotes > 99 ? "99+" : upvotes}
                    </div>
                    <div className='form-duplicate__title'>
                        {item.title ?? "--"}
                    </div>
                    <ChevronRightIcon />
                </div>);
            })}
            <div className="duplicate-line" />
            <div key={`duplicate-submitown`} className='form-duplicate' onClick={() => {
                formStore.sendForm(true);
            }}>
                <div className='form-duplicate__submiticon'>
                    <PlusIcon />
                </div>
                <div className='form-duplicate__title'>
                    {useConfigStore.getState().translateText("kaiSubmitIdea")}
                </div>
                <ChevronRightIcon />
            </div>
            {openedApp && <>
                <div className="duplicate-line" />
                <div key={`duplicate-cancel`} className='form-duplicate' onClick={() => {
                    useRouterStore.getState().setPage("menu");
                    CommunicationManager.getInstance().sendMessage({
                        name: "cleanup-drawings",
                    });
                }}>
                    <div className='form-duplicate__submiticon'>
                        <CheckIcon />
                    </div>
                    <div className='form-duplicate__title'>
                        {useConfigStore.getState().translateText("kaiSubmitIdeaDone")}
                    </div>
                </div>
            </>}
        </div>);
}

export default FeatureRequestSuggestions;
