import { Node } from '@tiptap/core';
import { useSessionStore } from '../Stores/Session';
import { get } from 'lodash';

export const ServerVariableNode = Node.create({
  name: 'serverVariable',
  group: 'inline',
  inline: true,

  addAttributes() {
    return {
      variableName: {
        default: this.options.variableName,
      },
      variableLabel: {
        default: this.options.variableName,
      },
      fallbackValue: {
        default: this.options.fallbackValue,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span.server-variable',
        getAttrs: (domNode) => {
          if (domNode instanceof HTMLElement) {
            const variableName = domNode.getAttribute('data-variable');
            const fallbackValue = domNode.getAttribute('data-fallback-value');
            const variableLabel = domNode.textContent;
            return {
              variableName,
              fallbackValue,
              variableLabel,
            };
          }
          return null;
        },
      },
    ];
  },

  renderHTML({ node }) {
    const variableName = node.attrs.variableName;
    const fallbackValue = node.attrs.fallbackValue;
    const session = useSessionStore.getState().session;
    const value = get({
        session: {
            ...session,
            firstName: session?.name?.split(' ')[0],
            lastName: session?.name?.split(' ')[1],
        },
    }, variableName, fallbackValue);

    return [
      'span',
      {
        class: 'server-variable',
        'data-variable': variableName,
        'data-fallback-value': fallbackValue,
      },
      value ?? fallbackValue ?? "",
    ];
  },

  renderText({ node }) {
    const fallbackValue = node.attrs.fallbackValue;

    return fallbackValue ?? "";
  },
});
