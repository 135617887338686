import { create } from "zustand";
import Communicator from "../Helper/Communicator";
import { useFormStore } from "./Form";

export enum AppMode {
  WIDGET = "widget",
  SURVEY_FULL = "survey_full",
  SURVEY_WEB = "survey_web",
  SURVEY = "survey",
}

export enum NavigationDirection {
  FORWARD = "forward",
  BACKWARD = "backward",
}

interface RouterState {
  appMode: AppMode;
  navigationDirection: NavigationDirection;
  currentPage: string;
  currentData: any;
  showBackButton: boolean;
  history: { page: string; data: any }[];
  setPage: (page: string, data?: any, appMode?: AppMode) => void;
  pushPage: (page: string, data?: any) => void;
  popPage: () => void;
}

export const useRouterStore = create<RouterState>()((set, get) => {
  /**
   * Helper function to change the page and update the state.
   * @param {string} page - The new page to navigate to.
   * @param {any} data - The data associated with the new page.
   * @param {AppMode} appMode - The application mode.
   * @param {boolean} resetHistory - Whether to reset the history.
   */
  const changePage = (
    page: string,
    data: any,
    appMode: AppMode,
    resetHistory: boolean
  ) => {
    Communicator.pageChanged(page);

    const navigationDirection =
      get().currentPage === "menu"
        ? NavigationDirection.FORWARD
        : NavigationDirection.BACKWARD;

    const newState: any = {
      currentPage: page,
      currentData: data,
      navigationDirection: navigationDirection,
      appMode,
    };

    if (resetHistory) {
      newState.history = [
        {
          page,
          data,
        },
      ];
    }

    if (page === "flow" && data.flowId) {
      // Set feedbackFlow in store.
      useFormStore.setState({
        feedbackFlow: data.flowId,
      });
    }

    set(newState);
  };

  return {
    appMode: AppMode.WIDGET,
    history: [],
    currentPage: "menu",
    currentData: {},
    showBackButton: true,
    navigationDirection: NavigationDirection.FORWARD,
    /**
     * Sets the current page and data, and resets the history.
     * Use this when you want to start a new navigation flow.
     * @param {string} page - The new page to navigate to.
     * @param {any} data - The data associated with the new page.
     * @param {AppMode} appMode - The application mode.
     */
    setPage: (page: string, data: any, appMode: AppMode = AppMode.WIDGET) => {
      changePage(page, data, appMode, true);
    },
    /**
     * Pushes a new page onto the history stack.
     * Use this when you want to navigate forward and maintain the current history.
     * @param {string} page - The new page to navigate to.
     * @param {any} data - The data associated with the new page.
     */
    pushPage: (page: string, data: any) => {
      const { history } = get();

      // Pages to ignore in history.
      const ignorePages = ["conversationloading", "flow"];
      if (page && !ignorePages.includes(page)) {
        set({
          history: [
            ...history,
            {
              page: page,
              data: data,
            },
          ],
        });
      }
      changePage(page, data, get().appMode, false);
    },
    /**
     * Pops the last page from the history stack and navigates back.
     * If the history is empty, logs a warning.
     */
    popPage: () => {
      const history = get().history;
      const currentPage = get().currentPage;

      const historyLength = history.length;

      if (historyLength === 0) {
        console.warn("No history available to pop");
        changePage("menu", {}, get().appMode, true);
        return;
      }

      const lastPage = history[historyLength - (historyLength > 1 ? 2 : 1)];
      const newHistory = history.slice(
        0,
        historyLength > 1 ? historyLength - 1 : 0
      );

      if (newHistory.length === 0 && currentPage === lastPage.page) {
        console.warn("No history available to pop");
        changePage("menu", {}, get().appMode, true);
        return;
      }

      changePage(lastPage.page, lastPage.data, get().appMode, false);

      set({
        history: history.slice(0, historyLength > 1 ? historyLength - 1 : 0),
        navigationDirection: NavigationDirection.BACKWARD,
      });
    },
  };
});
