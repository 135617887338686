import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as CheckIcon } from "../../Assets/check-solid.svg";
import { useFormStore } from "../../Stores/Form";
import "./MultipleChoiceMultiSelectFormItem.scss";

function shuffleArray(array: any[]) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

export const getIndex = (character: string) => {
  return character.charCodeAt(0) - 97;
};

export const getCharacter = (index: number) => {
  return String.fromCharCode(97 + index);
};

function MultipleChoiceMultiSelectFormItem({ formItem }: { formItem: any }) {
  const formData = useFormStore((state) => state.formData);
  const fieldFormData = formData[formItem.name];
  const fieldFormDataRef = useRef(fieldFormData);
  fieldFormDataRef.current = fieldFormData;
  const [options, setOption] = useState([] as any[]);
  const setFormData = useFormStore((state) => state.setFormData);

  useEffect(() => {
    var options = (formItem?.choices || []).map((choice: any) => {
      if (choice && choice.id && !choice.value) {
        return {
          ...choice,
          value: choice.id,
        };
      }
      return choice;
    });
    if (options) {
      if (formItem?.randomizeChoices) {
        shuffleArray(options);
      }

      setOption(options);
    }
  }, [formItem?.choices]);

  const handleFormDataChange = (choiceValue: any) => {
    let updatedFormDataValue = [...(fieldFormDataRef?.current?.value || [])];

    const itemAlreadySelected = updatedFormDataValue.find(
      (item: any) => item === choiceValue
    );

    // Toggle the selection: If it exists, remove it, otherwise add it
    if (itemAlreadySelected) {
      updatedFormDataValue = updatedFormDataValue.filter(
        (val) => val !== choiceValue
      );
    } else {
      updatedFormDataValue.push(choiceValue);
    }

    setFormData(
      formItem.name,
      {
        value: updatedFormDataValue,
        dirty: true,
      },
      false
    );
  };

  const handleUserKeyPress = useCallback((event: any) => {
    const { key, target } = event;
    if (key && target.tagName !== "INPUT" && target.tagName !== "TEXTAREA") {
      const index = getIndex(key);

      if (index >= 0 && index < formItem.choices.length) {
        const choiceValue = formItem.choices[index];

        handleFormDataChange(choiceValue?.value);
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const getLabel = (option: any) => {
    if (option && option.label) {
      return option.label;
    }
    if (typeof option === "object") {
      return option[""];
    }
    return option;
  };

  const renderOption = (option: any, index: number) => {
    var val = option;
    return (
      <div
        key={index}
        className={`multiple-choice-form-item-choice ${
          fieldFormData &&
          fieldFormData?.value?.includes(val?.value) &&
          "multiple-choice-form-item-choice--selected"
        }`}
        onClick={() => {
          handleFormDataChange(val?.value);
        }}
      >
        <div className="multiple-choice-form-item-choice-shortcut">
          {getCharacter(index)}
        </div>
        <div className="multiple-choice-form-item-choice-value">
          {getLabel(option)}
        </div>
        <div className="multiple-choice-form-item-choice-check">
          <CheckIcon />
        </div>
      </div>
    );
  };

  return (
    <div id={formItem.name} className="multiple-choice-form-item" aria-required={formItem.required}>
      {options.map((choice: any, index: number) => renderOption(choice, index))}
    </div>
  );
}

export default MultipleChoiceMultiSelectFormItem;
