import { useConfigStore } from '../../Stores/Config';

const calculateShadeColor = function (col: any, amt: any) {
    col = col.replace(/^#/, "");
    if (col.length === 3)
        col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];

    let [r, g, b] = col.match(/.{2}/g);
    [r, g, b] = [
        parseInt(r, 16) + amt,
        parseInt(g, 16) + amt,
        parseInt(b, 16) + amt,
    ];

    r = Math.max(Math.min(255, r), 0).toString(16);
    g = Math.max(Math.min(255, g), 0).toString(16);
    b = Math.max(Math.min(255, b), 0).toString(16);

    const rr = (r.length < 2 ? "0" : "") + r;
    const gg = (g.length < 2 ? "0" : "") + g;
    const bb = (b.length < 2 ? "0" : "") + b;

    return `#${rr}${gg}${bb}`;
};

const calculateContrast = (hex: any) => {
    var r = parseInt(hex.substr(1, 2), 16),
        g = parseInt(hex.substr(3, 2), 16),
        b = parseInt(hex.substr(5, 2), 16),
        yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 160 ? "#000000" : "#ffffff";
};

function CSSInjector() {
    const configStore = useConfigStore();
    const primaryColor = configStore.getUIColor();
    const backgroundColor = configStore.getBackgroundColor();
    const buttonColor = configStore.getButtonColor();
    const headerColor = configStore.getHeaderColor();
    const headerColor2 = configStore.getHeaderColorSecondary("headerColor2");
    const headerColor3 = configStore.getHeaderColorSecondary("headerColor3");
    const customCSS = configStore.config.customCSS;

    const contrastColor = calculateContrast(primaryColor);
    const contrastHeaderColor = calculateContrast(headerColor);
    const contrastButtonColor = calculateContrast(buttonColor);
    const contrastBackgroundColor = calculateContrast(backgroundColor);
    const isDarkMode = contrastBackgroundColor === "#ffffff";
    const headerDarkColor = calculateShadeColor(headerColor, contrastHeaderColor === "#ffffff" ? -35 : -15);
    const subTextColor = isDarkMode
        ? calculateShadeColor(backgroundColor, 140)
        : calculateShadeColor(backgroundColor, -140);
    const backgroundColorHover = isDarkMode
        ? calculateShadeColor(backgroundColor, 30)
        : calculateShadeColor(backgroundColor, -12);
    const hoverHoverColor = isDarkMode
        ? calculateShadeColor(backgroundColor, 80)
        : calculateShadeColor(backgroundColor, -30);
    const borderColor = isDarkMode
        ? calculateShadeColor(backgroundColor, 70)
        : calculateShadeColor(backgroundColor, -70);



    const css = `
    :root {
        --primary-color: ${primaryColor};
        --primary-color-AA: ${primaryColor}AA;
        --primary-color-20: ${primaryColor}20;
        --primary-color-55: ${primaryColor}55;
        --primary-color-44: ${primaryColor}44;
        --primary-color-22: ${primaryColor}22;
        --primary-color-11: ${primaryColor}11;
        --primary-color-07: ${primaryColor}07;
        --header-color: ${headerColor};
        --header-color-2: ${headerColor2};
        --header-color-3: ${headerColor3};
        --header-dark-color: ${headerDarkColor};
        --button-color: ${buttonColor};
        --background-color: ${backgroundColor};
        --background-color-99: ${backgroundColor}99;
        --background-color-EE: ${backgroundColor}ee;
        --background-color-1A: ${backgroundColor}1A;
        --primary-contrast-color: ${contrastColor};
        --header-contrast-color: ${contrastHeaderColor};
        --header-contrast-color-99: ${contrastHeaderColor}99;
        --header-contrast-color-33: ${contrastHeaderColor}33;
        --button-contrast-color: ${contrastButtonColor};
        --primary-text-color: ${contrastBackgroundColor};
        --primary-text-color-99: ${contrastBackgroundColor}99;
        --primary-text-color-11: ${contrastBackgroundColor}11;
        --secondary-text-color: ${subTextColor};
        --background-color-darker: ${backgroundColorHover};
        --background-color-dark: ${hoverHoverColor};
        --border-color: ${borderColor};
        --border-color-33: ${borderColor}33;
        --border-color-55: ${borderColor}55;
        --border-color-11: ${borderColor}11;
        --border-radius: ${configStore.getBorderRadius(1.0)}px;
        --border-radius-150: ${configStore.getBorderRadius(1.50)}px;
        --border-radius-60: ${configStore.getBorderRadius(0.6)}px;
        --border-radius-50: ${configStore.getBorderRadius(0.5)}px;
        --border-radius-40: ${configStore.getBorderRadius(0.4)}px;
        --border-radius-25: ${configStore.getBorderRadius(0.25)}px;
        --border-radius-15: ${configStore.getBorderRadius(0.15)}px;
    }
    `;

    return (
        <>
            <style>{css}</style>
            {customCSS && <style>{customCSS}</style>}
        </>
    );
}

export default CSSInjector;