import { ReactComponent as BG } from '../../Assets/BG.svg';
import { ReactComponent as BGCLASSIC } from '../../Assets/BGclassic.svg';
import { ReactComponent as BGCLASSICNOFADE } from '../../Assets/BGclassicnofade.svg';
import { ReactComponent as GleapDefaultIcon } from "../../Assets/DefaultLogo.svg";
import { ReactComponent as ArrowRight } from "../../Assets/angle-right-solid.svg";
import { ReactComponent as XIcon } from "../../Assets/x-mark.svg";
import BotCard from '../../Components/BotCard/BotCard';
import ChecklistCard from '../../Components/ChecklistCard/ChecklistCard';
import ConversationItem from '../../Components/ConversationItem/ConversationItem';
import HomeButtonOptions from '../../Components/HomeButtonOptions/HomeButtonOptions';
import IconButton from '../../Components/IconButton/IconButton';
import NewsCard from '../../Components/NewsCard/NewsCard';
import { closeWidgetAndReset, getOperatorInfo } from '../../Components/WidgetHeader/WidgetHeader';
import { truncate } from "../../Helper/FeedbackItemHelper";
import { useChecklistStore } from '../../Stores/Checklist';
import { useConfigStore } from '../../Stores/Config';
import { useNewsStore } from '../../Stores/News';
import { useRouterStore } from '../../Stores/Router';
import { useSessionStore } from '../../Stores/Session';
import { useTicketStore } from '../../Stores/Ticket';
import "./HomePage.scss";

function HomePage() {
    const configStore = useConfigStore();
    const sessionStore = useSessionStore();
    const ticketStore = useTicketStore();
    const newsStore = useNewsStore();
    const checklistStore = useChecklistStore();
    const routerStore = useRouterStore();
    const config = configStore.config;
    const showUsername =
        typeof config.hideUsersName === "undefined" || !config.hideUsersName;

    const unreadTickets = ticketStore.previousTickets.filter((ticket) => {
        if (ticket.sessionNotificationsUnread) {
            return true;
        }
        return false;
    });

    const canRenderNews = () => {
        if (!newsStore.latestArticle) {
            return false;
        }

        if (!config.showNewsOnHome) {
            return false;
        }

        return true;
    }

    const renderNews = () => {
        if (!canRenderNews()) {
            return null;
        }

        return (
            <div className='home-page-content-news' key="news">
                <NewsCard news={newsStore.latestArticle!} />
            </div>
        );
    }

    const renderChecklist = () => {
        if (checklistStore.checklists?.length <= 0) {
            return null;
        }

        const latestChecklist = checklistStore.checklists[0];
        return (
            <BotCard grow showBotAvatar>
                <ChecklistCard checklist={latestChecklist} key={latestChecklist.id} />
            </BotCard>
        );
    }

    const renderLogo = () => {
        if (config.hideLogo && config.hideLogo === true) {
            return null;
        }

        if (config.logo && config.logo.length > 0) {
            return (
                <img className="home-page-header-logo" src={config.logo} alt="" />
            );
        }

        return <GleapDefaultIcon className="home-page-header-logo" />;
    };

    const name = truncate(sessionStore?.getName() ?? "", 18);

    const renderTeamLogos = () => {
        if (config.hideTeam === true) {
            return null;
        }

        if (!(configStore.team && configStore.team.length > 0)) {
            return (
                <div className="operator-info-team-image">{[0, 1, 2].map((index) => {
                    return (<div key={index} />)
                })}</div>
            );
        }

        const team = configStore.team.slice(0, 3);
        return (
            <div className="operator-info-team-image">{team.map((teamUser, index) => {
                return (<img key={index} src={teamUser.profileImageUrl} />)
            })}</div>
        );
    }

    const renderMainCard = () => {
        if (unreadTickets && unreadTickets.length > 0) {
            return (<div className='widget-unread-messages-container'>
                {
                    unreadTickets.slice(0, 1).map((ticket, index) => {
                        const operatorInfo = getOperatorInfo(configStore, config, ticket);

                        return (
                            <BotCard key={index} avatar={operatorInfo?.image} showBotAvatar>
                                <ConversationItem ticket={ticket} index={index} key={ticket.bugId} />
                                <div
                                    className='reply-button'
                                    onClick={() => {
                                        routerStore.pushPage("conversation", {
                                            shareToken: ticket.shareToken,
                                        });
                                    }}>
                                    <span>{configStore.translateText("Reply")}</span>
                                    <ArrowRight />
                                </div>
                            </BotCard>
                        );
                    })
                }
            </div>);
        }

        const checklist = renderChecklist();
        if (checklist) {
            return checklist;
        }

        let operatorAvatarImage = null;
        if (config.operatorAvatarImage && config.operatorAvatarImage.length > 0) {
            operatorAvatarImage = (<img src={config.operatorAvatarImage} />);
        }

        const description = config.welcomeText;
        if (description && description.length > 0) {
            return (<BotCard avatar={operatorAvatarImage} showBotAvatar>
                {description}
            </BotCard>);
        }

        return null;
    }

    const renderBG = () => {
        if (config.bgType === 'image' && config.bgImage && config.bgImage.length > 0) {
            return (<img src={config.bgImage} className='home-page-bg home-page-bg--image' />);
        }

        if (config.bgType === 'classic') {
            if ((config?.fadebg ?? true)) {
                return <BGCLASSIC className={`home-page-bg`} />;
            }
            return <BGCLASSICNOFADE className={`home-page-bg`} />;
        }

        return <BG className={`home-page-bg ${(config.animateBG ?? true) ? 'bg-animating' : ''}`} />;
    }

    return (
        <div className={`home-page ${(config.bgBlur ?? true) ? 'home-page--blur' : ''}`}>
            {renderBG()}
            <div className='home-page-header'>
                {renderLogo()}
                <IconButton
                    className="home-page-header-close"
                    icon={XIcon}
                    onClick={() => {
                        closeWidgetAndReset();
                    }}
                />
            </div>
            <div className='home-page-inner'>
                <div className={`home-page-inner-content ${canRenderNews() ? 'home-page-inner-content--news' : ''}`}>
                    {configStore?.homeWidgetsLoaded ? <>
                        <div className='home-page-bot'>
                            {renderTeamLogos()}
                            <BotCard>
                                <div className='salut'>
                                    <span>{configStore.translateText("hi")}</span>
                                    {showUsername && name && name.length > 0 && (
                                        <span className="home-page-bot-title-name">{name}</span>
                                    )}
                                    {!config.hideWavingHandAfterName && (
                                        <span className="hand">{"👋"}</span>
                                    )}
                                </div>
                            </BotCard>
                            {renderMainCard()}
                        </div>
                        <div className="home-page-content">
                            <HomeButtonOptions />
                        </div>
                        {renderNews()}
                    </> : <div></div>}
                </div>
            </div>
        </div>
    );
}

export default HomePage;
