import { useEffect } from "react";
import { loadFromGleapCache } from "../../Helper/CacheReader";
import { useConfigStore } from "../../Stores/Config";
import { useFormStore } from "../../Stores/Form";
import { useSessionStore } from "../../Stores/Session";
import CaptureFormItem from "../CaptureFormItem/CaptureFormItem";
import CheckboxFormItem from "../CheckboxFormItem/CheckboxFormItem";
import MultipleChoiceFormItem from "../MultipleChoiceFormItem/MultipleChoiceFormItem";
import MultipleChoiceMultiSelectFormItem from "../MultipleChoiceMultiSelectFormItem/MultipleChoiceMultiSelectFormItem";
import NPSFormItem from "../NPSFormItem/NPSFormItem";
import OneToFiveFormItem from "../OneToFiveFormItem/OneToFiveFormItem";
import RatingFormItem from "../RatingFormItem/RatingFormItem";
import TextAreaFormItem from "../TextAreaFormItem/TextAreaFormItem";
import TextFormItem from "../TextFormItem/TextFormItem";
import UploadFormItem from "../UploadFormItem/UploadFormItem";
import "./FormItem.scss";

function FormItem({ formItem }: { formItem: any }) {
  const configStore = useConfigStore();
  const setFormData = useFormStore((state) => state.setFormData);
  const formData = useFormStore((state) => state.formData);
  const sessionStore = useSessionStore();
  const fieldFormData = formData[formItem.name];

  useEffect(() => {
    if (formItem) {
      if (formItem.defaultValue) {
        if (formItem.type === "priority-selection") {
          const defaultPrio = formItem?.choices?.find(
            (choice: any) => formItem.defaultValue === choice.id
          );
          if (defaultPrio) {
            setFormData(
              "priority",
              {
                value: defaultPrio?.value,
              },
              true
            );
          }
        } else {
          setFormData(formItem.name, {
            value: formItem.defaultValue,
            dirty: true,
            hidden: formItem.hideOnDefaultSet === true,
          });
        }
      } else {
        if (formItem.remember) {
          const data = loadFromGleapCache(
            `formitem-${sessionStore.sdkKey}-${formItem.name}`
          );
          if (data && data.value && data.value.length > 0) {
            setFormData(formItem.name, {
              value: data.value,
              dirty: true,
            });
          }
        }
      }
    }
  }, [formItem]);

  const renderFormWidget = () => {
    switch (formItem.type) {
      case "text":
        return <TextFormItem formItem={formItem} />;
      case "textarea":
        return <TextAreaFormItem formItem={formItem} />;
      case "capture":
        return <CaptureFormItem formItem={formItem} />;
      case "onetofive":
        return <OneToFiveFormItem formItem={formItem} />;
      case "netpromoterscore":
        return <NPSFormItem formItem={formItem} />;
      case "rating":
        return <RatingFormItem formItem={formItem} />;
      case "multiplechoice":
        return <MultipleChoiceFormItem formItem={formItem} />;
      case "multiple-choice-multi-select":
        return <MultipleChoiceMultiSelectFormItem formItem={formItem} />;
      case "priority-selection":
        return <MultipleChoiceFormItem formItem={formItem} prioritySelect />;
      case "upload":
        return <UploadFormItem formItem={formItem} />;
      case "privacypolicy":
        return <CheckboxFormItem formItem={formItem} />;
      default:
        return <div>{formItem.type}</div>;
    }
  };

  if (fieldFormData && fieldFormData.hidden) {
    return null;
  }

  return (
    <div className={`form-item form-item--${formItem.key}`}>
      {formItem.title && formItem.title.length > 0 && (
        <label htmlFor={formItem.key} className="form-item-label">
          {formItem.title}
          {formItem.required && (
            <span className="form-item-label--required">*</span>
          )}
        </label>
      )}
      {formItem.description && formItem.description.length > 0 && (
        <div className="form-item-description">{formItem.description}</div>
      )}
      {renderFormWidget()}
    </div>
  );
}

export default FormItem;
