import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import CSSInjector from "../../Components/CSSInjector/CSSInjector";
import TabBar from "../../Components/TabBar/TabBar";
import WidgetHeader from "../../Components/WidgetHeader/WidgetHeader";
import Communicator from "../../Helper/Communicator";
import { useAppViewStore } from "../../Stores/AppView";
import { useConfigStore } from "../../Stores/Config";
import { AppMode, useRouterStore } from "../../Stores/Router";
import AppModalView from "../AppModalView/AppModalView";
import AppView from "../AppView/AppView";
import ChecklistDetails from "../ChecklistDetails/ChecklistDetails";
import Checklists from "../Checklists/Checklists";
import ClassicHome from "../ClassicHome/ClassicHome";
import Conversation from "../Conversation/Conversation";
import ConversationLoading from "../ConversationLoading/ConversationLoading";
import Conversations from "../Conversations/Conversations";
import FeedbackFlow from "../FeedbackFlow/FeedbackFlow";
import HomePage from "../HomePage/HomePage";
import News from "../News/News";
import NewsDetails from "../NewsDetails/NewsDetails";
import "./App.scss";

export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current as any;
};

function App() {
  const routerStore = useRouterStore();
  const configStore = useConfigStore();
  const [scrolled, setScrolled] = useState(false);
  const appViewStore = useAppViewStore();
  const [headerOpacity, setHeaderOpacity] = useState(1.0);

  const updateAnimationStatus = (animating: boolean) => {
    if (document && document.body) {
      if (animating) {
        document.body.classList.add("animating");
      } else {
        document.body.classList.remove("animating");
      }
    }

    const htmlRootNodes = document.getElementsByTagName("html");
    if (htmlRootNodes && htmlRootNodes.length > 0) {
      if (animating) {
        htmlRootNodes[0].classList.add("animating");
      } else {
        htmlRootNodes[0].classList.remove("animating");
      }
    }
  };

  const resetBodyScroll = () => {
    updateAnimationStatus(false);
  };

  const resetBodyScrollDebounced = useCallback(
    debounce(() => resetBodyScroll(), 1000),
    []
  );

  const escFunction = useCallback((event: any) => {
    if (event.key === "Escape") {
      Communicator.closeWidget();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  useEffect(() => {
    if (routerStore.currentPage) {
      updateAnimationStatus(true);
      resetBodyScrollDebounced();
    }
  }, [routerStore.currentPage]);

  const renderMainComponents = () => {
    if (routerStore.currentPage === "flow") {
      return <FeedbackFlow />;
    } else if (routerStore.currentPage === "conversations") {
      return <Conversations />;
    } else if (routerStore.currentPage === "conversation") {
      return <Conversation />;
    } else if (routerStore.currentPage === "conversationloading") {
      return <ConversationLoading />;
    } else if (routerStore.currentPage === "news") {
      return <News />;
    } else if (routerStore.currentPage === "newsdetails") {
      return <NewsDetails />;
    } else if (routerStore.currentPage === "checklists") {
      return <Checklists />;
    } else if (routerStore.currentPage === "checklistdetails") {
      return <ChecklistDetails />;
    } else if (routerStore.currentPage === "appview") {
      return <AppView />;
    } else {
      if (configStore?.config?.v === 2) {
        return <HomePage />;
      }

      return <ClassicHome />;
    }
  };

  const showTabBar = () => {
    if (
      ["flow", "newsdetails", "conversation"].includes(routerStore.currentPage)
    ) {
      return false;
    }
    if (appViewStore.pageStack.length > 1) {
      return false;
    }
    return true;
  };

  return (
    <>
      <CSSInjector />
      <div
        className={`main-app ${
          routerStore.appMode === AppMode.SURVEY && "main-app--survey"
        } ${!showTabBar() && "main-app--tabbar--hidden"} ${
          (routerStore.appMode === AppMode.SURVEY_FULL ||
            routerStore.appMode === AppMode.SURVEY_WEB) &&
          "main-app--survey-full"
        } ${routerStore.currentPage === "menu" && "main-app--main"}`}
        dir={configStore.isRTLLang() ? "rtl" : "ltr"}
        onScroll={(e) => {
          if (e.currentTarget.scrollTop > 170) {
            setScrolled(true);
          } else {
            setScrolled(false);
          }

          var opacity = 1 - e.currentTarget.scrollTop / 200;
          if (opacity < 0) {
            opacity = 0;
          }
          if (opacity > 1) {
            opacity = 1;
          }
          setHeaderOpacity(opacity);
        }}
      >
        <WidgetHeader headerOpacity={headerOpacity} scrolled={scrolled} />
        <div
          className={`content-container animation-type-${routerStore.navigationDirection}`}
        >
          {renderMainComponents()}
        </div>
        <TabBar />
      </div>
      <AppModalView />
    </>
  );
}

export default App;
