import { useState } from 'react';
import { ReactComponent as BG } from '../../Assets/BG.svg';
import { ReactComponent as BGCLASSIC } from '../../Assets/BGclassic.svg';
import { ReactComponent as BGCLASSICNOFADE } from '../../Assets/BGclassicnofade.svg';
import { ReactComponent as GleapDefaultIcon } from "../../Assets/DefaultLogo.svg";
import { ReactComponent as XIcon } from "../../Assets/x-mark.svg";
import IconButton from '../../Components/IconButton/IconButton';
import { closeWidgetAndReset } from '../../Components/WidgetHeader/WidgetHeader';
import WidgetMenu from '../../Components/WidgetMenu/WidgetMenu';
import WidgetPoweredBy from '../../Components/WidgetPoweredBy/WidgetPoweredBy';
import { truncate } from "../../Helper/FeedbackItemHelper";
import { useConfigStore } from '../../Stores/Config';
import { useSessionStore } from '../../Stores/Session';
import "./ClassicHome.scss";

function ClassicHome() {
    const configStore = useConfigStore();
    const sessionStore = useSessionStore();
    const config = configStore.config;
    const [headerOpacity, setHeaderOpacity] = useState(1);

    const renderLogo = () => {
        if (config.hideLogo && config.hideLogo === true) {
            return null;
        }

        if (config.logo && config.logo.length > 0) {
            return (
                <img className="widget-header-main-logo" src={config.logo} alt="" />
            );
        }

        return <GleapDefaultIcon className="widget-header-main-logo" />;
    };

    const name = truncate(sessionStore?.getName() ?? "", 18);

    const renderTeamLogos = () => {
        if (config.hideTeam === true) {
            return null;
        }

        if (!(configStore.team && configStore.team.length > 0)) {
            return (
                <div className="operator-info-team-image">{[0, 1, 2].map((index) => {
                    return (<div key={index} />)
                })}</div>
            );
        }

        const team = configStore.team.slice(0, 3);
        return (
            <div className="operator-info-team-image">{team.map((teamUser, index) => {
                return (<img key={index} src={teamUser.profileImageUrl} />)
            })}</div>
        );
    }

    const renderBG = () => {
        if (config.bgType === 'image' && config.bgImage && config.bgImage.length > 0) {
            return (<img src={config.bgImage} className='home-page-bg home-page-bg--image' />);
        }

        if (config.bgType === 'classic') {
            if ((config?.fadebg ?? true)) {
                return <BGCLASSIC className={`home-page-bg`} />;
            }
            return <BGCLASSICNOFADE className={`home-page-bg`} />;
        }

        return <BG className={`home-page-bg ${(config.animateBG ?? true) ? 'bg-animating' : ''}`} />;
    }

    const renderClassicIntro = () => {
        const description = config.welcomeText;
        const showUsername =
            typeof config.hideUsersName === "undefined" || !config.hideUsersName;

        return (
            <div className='home-page-intro'>
                {renderTeamLogos()}
                <div className="widget-header-main-title">
                    <span>{configStore.translateText("hi")}</span>
                    {showUsername && name && name.length > 0 && (
                        <span className="widget-header-main-title-name">{name}</span>
                    )}
                    {!config.hideWavingHandAfterName && (
                        <span className="hand">{"👋"}</span>
                    )}
                </div>
                {description && description.length > 0 && (
                    <div className="widget-header-main-description">{description}</div>
                )}
            </div>
        );
    }

    return (
        <div className={`home-page home-page--classic ${(config.bgBlur ?? true) ? 'home-page--blur' : ''}`}>
            {renderBG()}
            <div className='home-page-header' style={{
                opacity: headerOpacity
            }}>
                {renderLogo()}
                <IconButton
                    className="home-page-header-close"
                    icon={XIcon}
                    onClick={() => {
                        closeWidgetAndReset();
                    }}
                />
            </div>
            <div className='home-page-inner' onScroll={(e) => {
                var opacity = 1 - (e.currentTarget.scrollTop / 100);
                if (opacity < 0) { opacity = 0 }
                if (opacity > 1) { opacity = 1 }
                setHeaderOpacity(opacity);
            }}>
                <div className='classic-menu'>
                    {renderClassicIntro()}
                    <WidgetMenu />
                    <WidgetPoweredBy />
                </div>
            </div>
        </div>
    );
}

export default ClassicHome;
