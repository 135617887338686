import React, { ReactNode } from 'react';
import { ReactComponent as GleapBotIcon } from '../../Assets/GleapBotIcon.svg';
import './BotCard.scss';

interface Props {
    children: ReactNode;
    showBotAvatar?: boolean;
    grow?: boolean;
    avatar?: any;
}

const BotCard: React.FC<Props> = ({ children, avatar, grow = false, showBotAvatar = false }) => {
    const renderAvatarContent = () => {
        if (!showBotAvatar) {
            return (<div className="bot-card-avatar-placeholder" />);
        }

        if (avatar) {
            return (<div className="bot-card-avatar">{avatar}</div>);
        }

        return (<GleapBotIcon className="bot-card-avatar" />);
    };

    return (
        <div className="bot-card" style={{
            flexGrow: grow ? 1 : 0,
        }}>
            {renderAvatarContent()}
            <div className="bot-card-content" style={{
                flexGrow: grow ? 1 : 0,
            }}>{children}</div>
        </div>
    );
};

export default BotCard;