import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { replaceAll } from '../../Helper/String';
import { useConfigStore } from "../../Stores/Config";
import { useTicketStore } from "../../Stores/Ticket";
import FormLoading from "../FormLoading/FormLoading";
import "./BotActionInputUpload.scss";

function BotActionInputUpload({ action }: { action: any }) {
    const attribute = action?.attribute;
    const configStore = useConfigStore();
    const ticketStore = useTicketStore();
    const [error, setError] = useState(false);
    const [uploading, setUploading] = useState(false);

    const {
        getRootProps,
        getInputProps,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        maxFiles: 1,
        multiple: false,
        accept: accepts,
        onDrop: (acceptedFiles) => {
            setError(false);

            if (
                acceptedFiles &&
                acceptedFiles.length > 0
            ) {
                setError(false);
                setUploading(true);

                const file = acceptedFiles[0];
                ticketStore.uploadFile(file).then((fileUrl) => {
                    setError(false);
                    setUploading(false);

                    sendInput({
                        value: fileUrl,
                        label: file.name,
                    });
                }).catch((err) => {
                    setError(true);
                    setUploading(false);
                });
            }
        }
    });

    if (!action || !attribute) {
        return null;
    }

    const sendInput = (option: any) => {
        if (!option) {
            return;
        }

        ticketStore.answerBotAction({
            text: option.value,
            message: option.label,
        });
    };

    var accepts: any = {};
    if (attribute.restrictions) {
        const restrictions = replaceAll(attribute.restrictions, " ", "").split(",");
        for (const restriction of restrictions) {
            accepts[restriction] = [];
        }
    }

    const renderDropzone = () => {
        if (uploading) {
            return <div className='upload-form-item-loading'><FormLoading /></div>;
        }

        return (<div {...getRootProps({ className: `dropzone ${isDragAccept && "dropzone--accept"} ${isDragReject && "dropzone--reject"}` })}>
            <input {...getInputProps()} />
            <p>{configStore.translateText("chooseFile")}</p>
        </div>);
    }

    return (<div className='bot-action-text-upload'>
        <div className='bot-action-text-upload-label'>
            {action.title}
        </div>
        <div className='bot-action-text-upload-innercontainer'>
            <div className="upload-form-item">
                {renderDropzone()}
            </div>
        </div>
    </div>);
}

export default BotActionInputUpload;