import { useConfigStore } from '../../Stores/Config';
import HomeButtonOptionsButton from '../HomeButtonOptionsButton/HomeButtonOptionsButton';
import "./HomeButtonOptions.scss";

function HomeButtonOptions() {
    const configStore = useConfigStore();

    return <>
        {configStore.homeWidgets.map((widget, index) => {
            if (widget.type === "menu") {
                return (
                    <HomeButtonOptionsButton key={index} menuItem={widget.config} index={index} />
                );
            }

            return null;
        })}</>;
}

export default HomeButtonOptions;
