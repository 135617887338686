import moment from "moment-timezone";
import { ReactComponent as GleapBotIcon } from "../../Assets/GleapBotIcon.svg";
import { ReactComponent as ChevronLeft } from "../../Assets/angle-left-solid.svg";
import { ReactComponent as XIcon } from "../../Assets/x-mark.svg";
import CommunicationManager from "../../Helper/CommunicationManager";
import { isOnline } from "../../Helper/OnlineState";
import { useAppViewStore } from "../../Stores/AppView";
import { useChecklistStore } from "../../Stores/Checklist";
import { useConfigStore } from "../../Stores/Config";
import { useFormStore } from "../../Stores/Form";
import { AppMode, useRouterStore } from "../../Stores/Router";
import { useTicketStore } from "../../Stores/Ticket";
import IconButton from "../IconButton/IconButton";
import "./WidgetHeader.scss";

export const getOperatorInfo = (configStore: any, config: any, ticket: any) => {
  if (ticket?.bot?.active) {
    const operatorAvatarImage = config.operatorAvatarImage;
    if (operatorAvatarImage && operatorAvatarImage.length > 0) {
      return {
        image: <img src={operatorAvatarImage} />,
        name: configStore.getBotName(),
        bot: true,
      };
    }

    // Fallback.
    return {
      image: <GleapBotIcon />,
      name: configStore.getBotName(),
      bot: true,
    };
  }

  if (ticket) {
    // Processing user.
    if (ticket.processingUser) {
      if (ticket.processingUser?.profileImageUrl) {
        return {
          image: <img src={ticket.processingUser?.profileImageUrl} />,
          name: `${ticket.processingUser?.firstName}`,
          bot: false,
        };
      }
    }

    // Latest comment.
    if (ticket.latestComment?.user) {
      if (ticket.latestComment.user?.profileImageUrl) {
        return {
          image: <img src={ticket.latestComment.user?.profileImageUrl} />,
          name: `${ticket.latestComment.user?.firstName}`,
          bot: false,
        };
      }
    }

    // Team fallback.
    return {
      image: null,
      name: config?.name ?? "Team",
      bot: false,
    };
  }

  return null;
};

export const resetWidget = () => {
  useAppViewStore.getState().reset();
  useFormStore.getState().clearFeedbackFlowOptions();
  useRouterStore.getState().setPage("menu");
  useRouterStore.setState({ showBackButton: true });
};

export const closeWidgetAndReset = () => {
  CommunicationManager.getInstance().sendMessage({
    name: "close-widget",
  });

  resetWidget();
};

function WidgetHeader({
  headerOpacity = 1.0,
  scrolled = false,
}: {
  headerOpacity?: number;
  scrolled?: boolean;
}) {
  const checklistStore = useChecklistStore();
  const configStore = useConfigStore();
  const formStore = useFormStore();
  const routerStore = useRouterStore();
  const appViewStore = useAppViewStore();
  const ticketStore = useTicketStore();
  const config = configStore.config;
  const sendingForm = formStore.sendingForm;

  const showBackButton = routerStore.showBackButton;

  const unreadTickets = ticketStore.previousTickets.filter((ticket) => {
    if (ticket.id === ticketStore.currentTicket?.id) {
      return false;
    }
    if (ticket.sessionNotificationsUnread) {
      return true;
    }
    return false;
  });

  const renderFormSubHeader = () => {
    return (
      <div className="widget-header-sub widget-header-sub-with-center">
        {showBackButton && !sendingForm && routerStore.appMode === AppMode.WIDGET && (
          <IconButton
            icon={ChevronLeft}
            onClick={() => {
              if (formStore.currentStep > 0) {
                formStore.showPrevStep();
              } else {
                routerStore.popPage();
                CommunicationManager.getInstance().sendMessage({
                  name: "cleanup-drawings",
                });
              }
            }}
          />
        )}
        <div className="widget-header-sub-center">
          <div className="widget-header-sub-title">
            {formStore.action?.title}
          </div>
        </div>
        {!sendingForm && routerStore.appMode !== AppMode.SURVEY_WEB && (
          <IconButton
            icon={XIcon}
            onClick={() => {
              closeWidgetAndReset();
            }}
          />
        )}
      </div>
    );
  };

  const renderConversationsSubHeader = () => {
    return (
      <div className="widget-header-sub">
        <div className="widget-header-sub-title widget-header-sub-title--no-back">
          {configStore.config.allConversationsMenuLabel}
        </div>
        <IconButton
          icon={XIcon}
          onClick={() => {
            closeWidgetAndReset();
          }}
        />
      </div>
    );
  };

  const renderConversationSubHeader = () => {
    const operatorInfo = getOperatorInfo(
      configStore,
      config,
      ticketStore?.currentTicket
    );
    const { online, nextOpeningTime } = isOnline();

    var replyTime = "";

    if (!configStore.config.showOnlineStatus) {
      replyTime = configStore.config.name ?? "";
    } else {
      if (ticketStore.currentTicket) {
        if (ticketStore.currentTicket?.type === "BOT") {
          replyTime = configStore.translateText("botReplyTime");
        } else {
          if (online) {
            replyTime = `${configStore.translateText(
              configStore.config.replyTime
            )}.`;
          } else {
            replyTime = `${configStore.translateText("weAreBack")} ${moment(
              nextOpeningTime
            ).fromNow()}.`;
          }
        }
      }
    }

    const renderOperatorImage = () => {
      if (operatorInfo?.image) {
        return <div className="operator-info-image">{operatorInfo?.image}</div>;
      }

      if (configStore.team && configStore.team.length > 0) {
        const team = configStore.team.slice(0, 3);
        return (
          <div
            className="operator-info-team-image"
            style={{
              marginRight: `${10 + (team.length - 1) * 22}px`,
            }}
          >
            {team.map((teamUser, index) => {
              return <img key={index} src={teamUser.profileImageUrl} />;
            })}
          </div>
        );
      }

      return null;
    };

    const operatorHeader = () => {
      if (!operatorInfo) {
        return (
          <>
            <div className="operator-info-content-image-loading"></div>
            <div className="operator-info-content-loading">
              <div className="operator-info-content-title-loading"></div>
              <div className="operator-info-content-subtitle-loading"></div>
            </div>
          </>
        );
      }

      return (
        <>
          {renderOperatorImage()}
          <div className="operator-info-content">
            <div className="operator-info-content-title">
              {operatorInfo.name}
            </div>
            <div className="operator-info-content-subtitle">{replyTime}</div>
          </div>
        </>
      );
    };

    return (
      <div
        className={`widget-header-sub ${
          operatorInfo?.bot ? "widget-header-sub--bot" : ""
        } ${!showBackButton && "widget-header-sub--hide-back-button"}`}
      >
        {showBackButton && (
          <IconButton
            unreadCount={unreadTickets.length}
            icon={ChevronLeft}
            onClick={() => {
              routerStore.popPage();
            }}
          />
        )}
        <div className="widget-header-operator-info">{operatorHeader()}</div>
        <IconButton
          icon={XIcon}
          onClick={() => {
            closeWidgetAndReset();
          }}
        />
      </div>
    );
  };

  const renderStatus = () => {
    const sts = config?.sts ?? false;

    if (
      !ticketStore.currentTicket?.type ||
      ticketStore.currentTicket?.type === "INQUIRY" ||
      ticketStore.currentTicket?.type === "BOT" ||
      !sts
    ) {
      return;
    }

    const currentStatus = ticketStore.currentTicket?.status;
    const stati = [
      {
        status: "OPEN",
        title: "submitted",
        isActive: true,
      },
      {
        status: "IN_PROGRESS",
        title: "inProgress",
        isActive: currentStatus !== "OPEN",
      },
      {
        status: "DONE",
        title: "resolved",
        isActive: currentStatus === "DONE",
      },
    ];

    return (
      <div className="widget-header-sub-content">
        <div className="widget-ticket-status">
          {stati.map((status, index) => {
            return (
              <div
                key={index}
                className={`widget-ticket-status-item ${
                  status.isActive ? "widget-ticket-status-item--active" : ""
                }`}
              >
                <div className="widget-ticket-status-item-bar"></div>
                <div className="widget-ticket-status-item-title">
                  {configStore.translateText(status.title)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderConversationSubHeaderTicket = () => {
    const operatorInfo = getOperatorInfo(
      configStore,
      config,
      ticketStore?.currentTicket
    );
    const { online, nextOpeningTime } = isOnline();

    var replyTime = "";

    if (!configStore.config.showOnlineStatus) {
      replyTime = configStore.config.name ?? "";
    } else {
      if (ticketStore.currentTicket) {
        if (online) {
          replyTime = `${configStore.translateText(
            configStore.config.replyTime
          )}.`;
        } else {
          replyTime = `${configStore.translateText("weAreBack")} ${moment(
            nextOpeningTime
          ).fromNow()}.`;
        }
      }
    }

    const renderOperatorImage = () => {
      if (operatorInfo?.image) {
        return <div className="operator-info-image">{operatorInfo?.image}</div>;
      }

      if (configStore.team && configStore.team.length > 0) {
        const team = configStore.team.slice(0, 3);
        return (
          <div
            className="operator-info-team-image"
            style={{
              marginRight: `${10 + (team.length - 1) * 22}px`,
            }}
          >
            {team.map((teamUser, index) => {
              return <img key={index} src={teamUser.profileImageUrl} />;
            })}
          </div>
        );
      }

      return null;
    };

    const operatorHeader = () => {
      if (!operatorInfo) {
        return <></>;
      }

      return (
        <>
          {renderOperatorImage()}
          <div className="operator-info-content">
            <div className="operator-info-content-title">
              {operatorInfo.name}
            </div>
            <div className="operator-info-content-subtitle">{replyTime}</div>
          </div>
        </>
      );
    };

    return (
      <div className="widget-header-sub widget-header-sub-ticket widget-header-sub-center">
        <div className="widget-header-sub-header">
          {showBackButton && (
            <IconButton
              unreadCount={unreadTickets.length}
              icon={ChevronLeft}
              onClick={() => {
                routerStore.popPage();
              }}
            />
          )}
          <div className="widget-header-operator-info">{operatorHeader()}</div>
          <IconButton
            icon={XIcon}
            onClick={() => {
              closeWidgetAndReset();
            }}
          />
        </div>
        {renderStatus()}
      </div>
    );
  };

  const renderNewsSubHeader = () => {
    return (
      <div className="widget-header-sub">
        <div className="widget-header-sub-title widget-header-sub-title--no-back">
          {configStore.config.newsMenuLabel}
        </div>
        <IconButton
          icon={XIcon}
          onClick={() => {
            closeWidgetAndReset();
          }}
        />
      </div>
    );
  };

  const renderChecklistSubHeader = () => {
    return (
      <div className="widget-header-sub">
        <div className="widget-header-sub-title widget-header-sub-title--no-back">
          {configStore.config.checklistMenuLabel}
        </div>
        <IconButton
          icon={XIcon}
          onClick={() => {
            closeWidgetAndReset();
          }}
        />
      </div>
    );
  };

  const renderChecklistDetailsHeader = () => {
    return (
      <div className="widget-header-sub">
        {checklistStore.checklists?.length > 1 ? (
          <IconButton
            icon={ChevronLeft}
            onClick={() => {
              routerStore.popPage();
            }}
          />
        ) : (
          <div className="close-spacer"></div>
        )}
        <div className={`widget-header-sub-title`}>
          {configStore.config.checklistMenuLabel}
        </div>
        <IconButton
          icon={XIcon}
          onClick={() => {
            closeWidgetAndReset();
          }}
        />
      </div>
    );
  };

  const renderAppViewHeader = () => {
    const showBack =
      (appViewStore.pageStack.length > 1 || routerStore.history.length > 1) &&
      showBackButton;

    return (
      <div className="widget-header-sub">
        {showBack && (
          <IconButton
            icon={ChevronLeft}
            onClick={() => {
              if (appViewStore.pageStack.length > 1) {
                appViewStore.popPage();
              } else {
                routerStore.popPage();
              }
            }}
          />
        )}
        <div
          className={`widget-header-sub-title ${
            !showBack && "widget-header-sub-title--no-back"
          }`}
        >
          {configStore.translateText(appViewStore.getCurrentPage())}
        </div>
        {appViewStore.pageStack.length <= 1 ? (
          <IconButton
            icon={XIcon}
            onClick={() => {
              closeWidgetAndReset();
            }}
          />
        ) : (
          <div className="close-spacer"></div>
        )}
      </div>
    );
  };

  const renderAppExtendedViewHeader = () => {
    const showBack =
      (appViewStore.pageStack.length > 1 || routerStore.history.length > 1) &&
      showBackButton;

    return (
      <div
        className={`widget-header-sub widget-header-sub--extended ${
          appViewStore.showExtendedViewTitle &&
          "widget-header-sub--extended--scrolled"
        } ${!showBack && "widget-header-sub--hide-back-button"}`}
      >
        {showBack && (
          <IconButton
            icon={ChevronLeft}
            onClick={() => {
              if (appViewStore.pageStack.length > 1) {
                appViewStore.popPage();
              } else {
                routerStore.popPage();
              }
            }}
          />
        )}
        <div className={`widget-header-sub-title`}>
          {configStore.translateText(appViewStore.getCurrentPage())}
        </div>
        <IconButton
          className="widget-header-app-close"
          icon={XIcon}
          onClick={() => {
            closeWidgetAndReset();
          }}
        />
      </div>
    );
  };

  const renderHeader = () => {
    if (routerStore.currentPage === "menu") {
      return null;
    }
    if (routerStore.currentPage === "flow") {
      return renderFormSubHeader();
    }
    if (routerStore.currentPage === "conversations") {
      return renderConversationsSubHeader();
    }
    if (
      routerStore.currentPage === "conversation" ||
      routerStore.currentPage === "conversationloading"
    ) {
      if (
        !ticketStore.currentTicket?.type ||
        ticketStore.currentTicket?.type === "BOT" ||
        ticketStore.currentTicket?.type === "INQUIRY"
      ) {
        return renderConversationSubHeader();
      }
      return renderConversationSubHeaderTicket();
    }
    if (routerStore.currentPage === "news") {
      return renderNewsSubHeader();
    }
    if (routerStore.currentPage === "checklists") {
      return renderChecklistSubHeader();
    }
    if (routerStore.currentPage === "checklistdetails") {
      return renderChecklistDetailsHeader();
    }
    if (routerStore.currentPage === "appview") {
      if (appViewStore.extendedView) {
        return renderAppExtendedViewHeader();
      }
      return renderAppViewHeader();
    }
    return null;
  };

  const renderShadow = () => {
    return (
      <div
        className={`widget-header--shadow ${
          config.disableBGFade && "widget-header--nofade"
        } ${config.disableBGGradient && "widget-header--nogradient"}`}
      />
    );
  };

  return (
    <div
      className={`widget-header ${scrolled && "widget-header--scrolled"} ${
        routerStore.currentPage === "menu" && "widget-header--bg"
      } ${
        routerStore.currentPage === "conversation" &&
        ticketStore.currentTicket?.type === "BUG" &&
        "widget-header--bglarge"
      }`}
    >
      {config.v !== 2 && renderShadow()}
      {renderHeader()}
    </div>
  );
}

export default WidgetHeader;
