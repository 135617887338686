import { useState } from "react";
import { useConfigStore } from "../../Stores/Config";
import { useFormStore } from "../../Stores/Form";
import { ReactComponent as ArrowRight } from "../../Assets/angle-right-solid.svg";
import "./TextFormItem.scss";

function TextFormItem({ formItem }: { formItem: any }) {
  const configStore = useConfigStore();
  const formData = useFormStore((state) => state.formData);
  const fieldFormData = formData[formItem.name];
  const setFormData = useFormStore((state) => state.setFormData);
  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  const formStore = useFormStore();
  const isAloneInPage = formStore.currentElementIsAlone();

  var classes = "text-form-item-input";
  if (
    fieldFormData &&
    fieldFormData.dirty &&
    !fieldFormData.valid &&
    !focused
  ) {
    classes += " text-form-item-input--invalid";
  }

  return (
    <div
      className={`text-form-item ${isAloneInPage && "text-form-item--button"}`}
    >
      <input
        id={formItem.name}
        className={classes}
        type={formItem.inputtype}
        autoComplete={formItem.inputtype === "email" ? "email" : undefined}
        value={fieldFormData && fieldFormData.value ? fieldFormData.value : ""}
        name={formItem.name}
        onFocus={onFocus}
        onBlur={onBlur}
        aria-required={formItem.required}
        placeholder={formItem.placeholder}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            formStore.nextAction();
          }
        }}
        onChange={(e: any) => {
          setFormData(formItem.name, {
            value: e.target.value,
            dirty: true,
          });
        }}
      />
      {isAloneInPage && (
        <button
          className={`${!formStore.isCurrentStepValid() && "button--disabled"}`}
          onClick={() => {
            formStore.nextAction();
          }}
          type="button"
        >
          <ArrowRight />
        </button>
      )}
    </div>
  );
}

export default TextFormItem;
