import { useCallback, useEffect, useState } from "react";
import { ReactComponent as CheckIcon } from "../../Assets/check-solid.svg";
import { useFormStore } from "../../Stores/Form";
import "./MultipleChoiceFormItem.scss";

function shuffleArray(array: any[]) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

function MultipleChoiceFormItem({
  formItem,
  prioritySelect,
}: {
  formItem: any;
  prioritySelect?: boolean;
}) {
  const formData = useFormStore((state) => state.formData);
  const [options, setOption] = useState([] as any[]);
  const setFormData = useFormStore((state) => state.setFormData);
  const formKey = prioritySelect ? "priority" : formItem?.name;
  const fieldFormData = formData[formKey];

  useEffect(() => {
    var options = (formItem?.choices || []).map((choice: any) => {
      if (choice && choice.id && !choice.value) {
        return {
          ...choice,
          value: choice.id,
        };
      }
      return choice;
    });
    if (options) {
      if (formItem?.randomizeChoices) {
        shuffleArray(options);
      }

      setOption(options);
    }
  }, [formItem?.choices]);

  const getIndex = (character: string) => {
    return character.charCodeAt(0) - 97;
  };

  const getCharacter = (index: number) => {
    return String.fromCharCode(97 + index);
  };

  const handleUserKeyPress = useCallback((event: any) => {
    const { key, target } = event;
    if (key && target.tagName !== "INPUT" && target.tagName !== "TEXTAREA") {
      const index = getIndex(key);
      if (index >= 0 && index < options.length) {
        setFormData(
          formKey,
          {
            value: options[index]?.value,
            dirty: true,
          },
          true
        );
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const getLabel = (option: any) => {
    if (option && option.label) {
      return option.label;
    }
    if (typeof option === "object") {
      return option[""];
    }
    return option;
  };

  const renderPriorityBubble = (index: number) => {
    return (
      <div
        className={`multiple-choice-form-item-choice-priority multiple-choice-form-item-choice-priority--${index}`}
      />
    );
  };

  const renderOption = (option: any, index: number) => {
    return (
      <div
        key={index}
        className={`multiple-choice-form-item-choice ${
          fieldFormData &&
          fieldFormData.value === option?.value &&
          "multiple-choice-form-item-choice--selected"
        }`}
        onClick={() => {
          setFormData(
            formKey,
            {
              value: option?.value,
              dirty: true,
            },
            true
          );
        }}
      >
        {prioritySelect ? (
          renderPriorityBubble(index)
        ) : (
          <div className="multiple-choice-form-item-choice-shortcut">
            {getCharacter(index)}
          </div>
        )}
        <div className="multiple-choice-form-item-choice-value">
          {getLabel(option)}
        </div>
        <div className="multiple-choice-form-item-choice-check">
          <CheckIcon />
        </div>
      </div>
    );
  };

  return (
    <div id={formItem.name} className="multiple-choice-form-item" aria-required={formItem.required}>
      {options.map((choice: any, index: number) => renderOption(choice, index))}
    </div>
  );
}

export default MultipleChoiceFormItem;
