import { useRouterStore } from "../Stores/Router";
import { useSessionStore } from "../Stores/Session";
import CommunicationManager from "./CommunicationManager";

export default class Communicator {
  static openURL(url: string, newTab: boolean = false) {
    try {
      // Retrieve the session object from the store
      const session = useSessionStore.getState().session;

      // Function to replace placeholders in the URL
      const replaceUrlPlaceholders = (url: string, session: any): string => {
        return url.replace(/{{\s*session\.(\w+)\s*}}/g, (match, p1) => {
          return session[p1] ?? "";
        });
      };

      // Replace placeholders in the URL
      const processedUrl = replaceUrlPlaceholders(url, session);

      // Use CommunicationManager to send the processed URL
      CommunicationManager.getInstance().sendMessage({
        name: "open-url",
        data: processedUrl,
        newTab: newTab,
      });
    } catch (error) {
      CommunicationManager.getInstance().sendMessage({
        name: "open-url",
        data: url,
        newTab: newTab,
      });
    }
  }

  static runCustomAction(action: string) {
    CommunicationManager.getInstance().sendMessage({
      name: "run-custom-action",
      data: action,
    });
  }

  static openImage(url: string) {
    CommunicationManager.getInstance().sendMessage({
      name: "open-image",
      data: {
        url,
      },
    });
  }

  static pageChanged(pageName: string) {
    CommunicationManager.getInstance().sendMessage({
      name: "page-changed",
      data: {
        name: pageName,
      },
    });
  }

  static closeWidget() {
    CommunicationManager.getInstance().sendMessage({
      name: "close-widget",
    });

    useRouterStore.setState({ showBackButton: true });
  }

  static playPing() {
    CommunicationManager.getInstance().sendMessage({
      name: "play-ping",
      data: {},
    });
  }

  static notifyEvent(name: string, data?: any) {
    CommunicationManager.getInstance().sendMessage({
      name: "notify-event",
      data: {
        type: name,
        data: data,
      },
    });
  }
}
