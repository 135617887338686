import { useAppModalViewStore } from '../../Stores/AppModalView';
import { useSessionStore } from '../../Stores/Session';
import { useTicketStore } from '../../Stores/Ticket';
import "./BotActionInputCalendly.scss";

function BotActionInputCalendly({ action }: { action: any }) {
    const appModalViewStore = useAppModalViewStore();
    const session = useSessionStore.getState().session;
    const ticketStore = useTicketStore();

    if (!action) {
        return null;
    }

    return (<div className='bot-action-calendly-input'>
        <div className='bot-action-calendly-input-label'>
            {action.intro}
        </div>
        <button
            className='action-button'
            onClick={() => {
                if (!action.calendlyLink) {
                    return;
                }

                appModalViewStore.openApp(`${action.calendlyLink}?embed_domain=messenger-app.gleap.io&embed_type=Inline&hide_gdpr_banner=1&name=${encodeURIComponent(session?.name ?? "")}&email=${encodeURIComponent(session?.email ?? "")}&utm_source=Gleap&utm_medium=Messenger`, "Calendly", () => {

                }, (event) => {
                    if (event && event.event === 'calendly.event_scheduled') {
                        const uri = event.payload?.event?.uri;

                        ticketStore.answerBotAction({
                            calendlyUri: uri
                        });

                        appModalViewStore.closeApp();
                    }
                });
            }}
            type="button"
        >
            {action.buttonText ?? "Book now"}
        </button>
    </div>);
}

export default BotActionInputCalendly;