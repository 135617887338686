import { useEffect, useRef, useState } from "react";
import ChatMessage from "../../Components/ChatMessage/ChatMessage";
import FeatureRequestSuggestions from "../../Components/FeatureRequestSuggestions/FeatureRequestSuggestions";
import Form from "../../Components/Form/Form";
import SurveyIntro from "../../Components/SurveyIntro/SurveyIntro";
import WidgetPoweredBy from "../../Components/WidgetPoweredBy/WidgetPoweredBy";
import { useConfigStore } from "../../Stores/Config";
import { useFormStore } from "../../Stores/Form";
import { AppMode, useRouterStore } from "../../Stores/Router";
import "./FeedbackFlow.scss";
import CommunicationManager from "../../Helper/CommunicationManager";
import { resetWidget } from "../../Components/WidgetHeader/WidgetHeader";

function FeedbackFlow() {
  const routerStore = useRouterStore();
  const [currentAction, setCurrentAction] = useState(null as any);
  const formStore = useFormStore();
  const configStore = useConfigStore();
  const bottomRef = useRef(null as any);
  const hasChatStyle = formStore?.action?.chatStyle ?? false;
  const showInput =
    !hasChatStyle ||
    formStore.formAnswersLastType === "question" ||
    formStore.formError;

  const scrollToBottom = () => {
    if (bottomRef) {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });

      setTimeout(() => {
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 1000);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [
    formStore.formAnswers,
    formStore.formError,
    formStore.formSent,
    formStore.duplicates,
  ]);

  useEffect(() => {
    if (currentAction) {
      formStore.resetForm();
      formStore.setFormAction(currentAction);
      formStore.validateForm();
    }
  }, [currentAction]);

  const closeWidgetAndReset = () => {
    CommunicationManager.getInstance().sendMessage({
      name: "close-widget",
    });

    resetWidget();
  };

  useEffect(() => {
    if (routerStore.currentData && routerStore.currentData.flowId) {
      configStore
        .loadAction(routerStore.currentData.flowId)
        .then((action: any) => {
          if (action) {
            setCurrentAction(action);
          } else {
            closeWidgetAndReset();
          }
        })
        .catch(() => {
          closeWidgetAndReset();
        });
    }
  }, [routerStore.currentData]);

  const renderForm = () => {
    if (
      !hasChatStyle &&
      formStore.duplicates &&
      formStore.duplicates.length > 0
    ) {
      return (
        <div className="suggestions-container">
          <div className="suggestions-container--title">
            {configStore.translateText("suggestFeatureRequest")}
          </div>
          <FeatureRequestSuggestions />
        </div>
      );
    }

    if (routerStore.appMode === AppMode.WIDGET) {
      if (!showInput) {
        return;
      }

      return (
        <div className={`feedback-flow-inner-container`}>
          <div className="feedback-flow">
            <Form animated={false} />
          </div>
        </div>
      );
    }

    return (
      <div className="feedback-flow">
        <SurveyIntro currentAction={formStore.action} />
        <Form />
      </div>
    );
  };

  const renderDuplicates = () => {
    if (formStore.duplicates && formStore.duplicates.length > 0) {
      return (
        <>
          <ChatMessage
            key="prelast"
            lastOfGroup={false}
            chatMessage={{
              data: {
                content: configStore.translateText("suggestFeatureRequest"),
              },
              type: "TEXT",
            }}
            showTime={false}
          />
          <ChatMessage
            key="lastmess"
            lastOfGroup={true}
            chatMessage={{
              data: {},
              type: "FEATURE_REQUEST_DUPLICATES",
            }}
            showTime={false}
          />
        </>
      );
    }

    return null;
  };

  return (
    <div
      className={`feedback-flow-container ${
        hasChatStyle
          ? "feedback-flow-container--chat-style"
          : "feedback-flow-container--form-style"
      }`}
    >
      {currentAction && routerStore.appMode === AppMode.WIDGET && (
        <div
          className={`feedback-flow-chat ${
            showInput && "feedback-flow-chat--has-input"
          }`}
        >
          <div
            style={{
              flexGrow: 1,
            }}
          ></div>
          {hasChatStyle && currentAction.introMessage && (
            <ChatMessage
              key="lastStylePr"
              lastOfGroup={formStore.formAnswers.length === 0 || !hasChatStyle}
              chatMessage={{
                data: {
                  content: currentAction.introMessage,
                },
                type: "TEXT",
              }}
              showTime={false}
            />
          )}
          {hasChatStyle &&
            formStore.formAnswers.map((item, index) => {
              if (item.type === "question") {
                // Question
                return (
                  <ChatMessage
                    key={`question-${index}`}
                    lastOfGroup={true}
                    chatMessage={{
                      data: {
                        content: item.data,
                      },
                      type: "TEXT",
                    }}
                    typingDelay={item.delay}
                    isCreator={false}
                    showTime={false}
                  />
                );
              }
              
              // Answer
              return (
                <ChatMessage
                  key={`answer-${index}`}
                  lastOfGroup={true}
                  chatMessage={{
                    data: {
                      content: item.data,
                    },
                    type: "TEXT",
                  }}
                  isCreator={true}
                  showTime={false}
                />
              );
            })}
          {hasChatStyle && formStore.formSent && (
            <ChatMessage
              key="lastStyle"
              lastOfGroup={true}
              chatMessage={{
                data: {
                  content: formStore.action.thanksMessage,
                },
                type: "TEXT",
              }}
              showTime={false}
            />
          )}
          {hasChatStyle && formStore.checkingDuplicates && (
            <ChatMessage
              key="lastDup"
              lastOfGroup={true}
              chatMessage={{
                data: {
                  content: "Checking for duplicates...",
                },
                type: "TEXT",
              }}
              forceTyping={true}
              showTime={false}
            />
          )}
          {hasChatStyle && formStore.sendingForm && (
            <ChatMessage
              key="lastSend"
              lastOfGroup={true}
              chatMessage={{
                data: {
                  content: "Sending message...",
                },
                type: "TEXT",
              }}
              forceTyping={true}
              showTime={false}
            />
          )}
          {hasChatStyle && renderDuplicates()}
          <WidgetPoweredBy />
          <div ref={bottomRef} />
        </div>
      )}
      {renderForm()}
    </div>
  );
}

export default FeedbackFlow;
