import { useEffect, useRef, useState } from "react";
import FormLoading from "../../Components/FormLoading/FormLoading";
import IconButton from "../../Components/IconButton/IconButton";
import { useAppModalViewStore } from "../../Stores/AppModalView";
import { ReactComponent as XIcon } from "../../Assets/x-mark.svg";
import "./AppModalView.scss";

function AppModalView() {
  const appModalViewStore = useAppModalViewStore();
  const iFrameRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (appModalViewStore.currentApp?.url) {
      setLoading(true);
    }
  }, [appModalViewStore.currentApp?.url]);

  useEffect(() => {
    if (iFrameRef && iFrameRef.current) {
      appModalViewStore.setAppFrame(iFrameRef.current);
    }
  }, [iFrameRef]);

  if (!appModalViewStore.currentApp) {
    return null;
  }

  return (
    <div className="app-modal-view-main">
      <div className="widget-header">
        <div className="widget-header-sub">
          <div className="widget-header-sub-title widget-header-sub-title--no-back">
            {appModalViewStore.currentApp.name}
          </div>
          <IconButton
            icon={XIcon}
            onClick={() => {
              appModalViewStore.closeApp();
            }}
          />
          {loading && (
            <div className="app-modal-view-loader">
              <div className="app-modal-view-loader-inner"></div>
            </div>
          )}
        </div>
      </div>
      <div className={`app-modal-view ${loading && "app-modal-view--loading"}`}>
        {loading && <FormLoading />}
        <iframe
          allowFullScreen
          src={appModalViewStore.currentApp.url}
          frameBorder="0"
          allow="autoplay; encrypted-media; fullscreen;"
          ref={iFrameRef}
          onLoad={() => {
            setTimeout(() => {
              setLoading(false);
            }, 800);
          }}
        />
      </div>
    </div>
  );
}

export default AppModalView;
