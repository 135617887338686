import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { replaceAll } from '../../Helper/String';
import { useConfigStore } from '../../Stores/Config';
import { useFormStore } from '../../Stores/Form';
import { useTicketStore } from '../../Stores/Ticket';
import FormLoading from '../FormLoading/FormLoading';
import { ReactComponent as XIcon } from "../../Assets/x-mark.svg";
import { ReactComponent as PaperClipIcon } from "../../Assets/paperclip-solid.svg";
import "./UploadFormItem.scss";

function UploadFormItem({ formItem }: { formItem: any }) {
    const ticketStore = useTicketStore();
    const [error, setError] = useState(false);
    const [uploading, setUploading] = useState(false);

    var accepts: any = {};
    if (formItem.restrictions) {
        const restrictions = replaceAll(formItem.restrictions, " ", "").split(",");
        for (const restriction of restrictions) {
            accepts[restriction] = [];
        }
    }
    
    const {
        getRootProps,
        getInputProps,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        maxFiles: 1,
        multiple: false,
        accept: accepts,
        onDrop: (acceptedFiles) => {
            setError(false);

            if (
                acceptedFiles &&
                acceptedFiles.length > 0
            ) {
                setError(false);
                setUploading(true);

                const file = acceptedFiles[0];
                ticketStore.uploadFile(file).then((fileUrl) => {
                    setError(false);
                    setUploading(false);
                    setFormData(formItem.name, {
                        value: fileUrl,
                        dirty: true,
                        fileName: file.name
                    });
                }).catch((err) => {
                    setError(true);
                    setUploading(false);
                });
            }
        }
    });
    
    const configStore = useConfigStore();
    const formData = useFormStore(state => state.formData);
    const fieldFormData = formData[formItem.name];
    const setFormData = useFormStore(state => state.setFormData);

    const renderDropzone = () => {
        if (uploading) {
            return <div className='upload-form-item-loading'><FormLoading /></div>;
        }

        if (fieldFormData && fieldFormData.value && fieldFormData.fileName) {
            return (
                <div className='file-added-button'>
                    <PaperClipIcon />
                    <span>{fieldFormData.fileName}</span>
                    <div className='file-added-button-remove' onClick={() => {
                        setFormData(formItem.name, {
                            value: undefined,
                            dirty: true,
                            fileName: undefined
                        });
                    }}>
                        <XIcon /></div>
                </div >);
        }

        return (<div {...getRootProps({ className: `dropzone ${isDragAccept && "dropzone--accept"} ${isDragReject && "dropzone--reject"}` })}>
            <input {...getInputProps()} />
            <p>{configStore.translateText("chooseFile")}</p>
        </div>);
    }

    return (
        <div id={formItem.name} className="upload-form-item" aria-required={formItem.required}>
            {renderDropzone()}
        </div>
    );
}

export default UploadFormItem;
