import { create } from 'zustand';

interface AppModalViewState {
    currentApp?: {
        id: string;
        name: string;
        url: string;
    };
    appFrame?: HTMLIFrameElement;
    openApp: (
        url: string,
        title?: string,
        onClose?: () => void,
        onEvent?: (event?: any) => void
    ) => void;
    eventListener?: any;
    onClose?: () => void;
    onEvent?: (event?: any) => void;
    closeApp: () => void;
    setAppFrame: (frame: HTMLIFrameElement) => void;
}

const getDomain = (url: string) => {
    try {
        var domain = new URL(url).hostname;
        return domain;
    } catch (e) {
        return null;
    }
}

export const useAppModalViewStore = create<AppModalViewState>()((set, get) => ({
    currentApp: undefined,
    appFrame: undefined,
    extendedView: false,
    showExtendedViewTitle: false,
    pageStack: [],
    onClose: undefined,
    onEvent: undefined,
    eventListener: undefined,
    openApp: (
        url: string,
        title?: string,
        onClose?: () => void,
        onEvent?: (event?: any) => void
    ) => {
        const domain = getDomain(url);
        if (!domain) {
            return;
        }

        set({
            currentApp: {
                id: url,
                name: title || "",
                url: url,
            },
            onClose,
            onEvent,
        });

        const messageEventListener = (event: MessageEvent) => {
            if (event?.origin?.includes(domain) && onEvent) {
                onEvent(event.data);
            }
        };
        set({ eventListener: messageEventListener });
        window.addEventListener('message', messageEventListener);
    },
    closeApp: () => {
        const { eventListener, onClose } = get();
        if (eventListener) {
            window.removeEventListener('message', eventListener);
        }
        if (onClose) {
            onClose();
        }
        set({ currentApp: undefined, onClose: undefined, onEvent: undefined, eventListener: undefined });
    },
    setAppFrame: (frame: HTMLIFrameElement) => {
        set({
            appFrame: frame,
        });
    },
}));
