import { ReactComponent as Check } from "../../Assets/check.svg";
import { ChecklistStep } from "../../Stores/Checklist";
import { useConfigStore } from "../../Stores/Config";
import Heading from "@tiptap/extension-heading";
import Image from "@tiptap/extension-image";
import Mention from "@tiptap/extension-mention";
import Placeholder from "@tiptap/extension-placeholder";
import TextStyle from "@tiptap/extension-text-style";
import Youtube from "@tiptap/extension-youtube";
import { generateHTML } from "@tiptap/html";
import StarterKit from "@tiptap/starter-kit";
import "./ChecklistTask.scss";
import { replaceAll } from "../../Helper/String";
import IFrame from "../../Helper/IFrame";
import { HelpCenterArticleExtension } from "../../Helper/HelpcenterExtension";
import CustomLink from "../../Helper/CustomLink";
import { Callout } from "../../Helper/Callout";
import { ServerVariableNode } from "../../Helper/VariableExtension";
import Communicator from "../../Helper/Communicator";

function ChecklistTask({
  step,
  index,
  isOpen,
  isDone,
  shouldOpen,
  completed,
  triggerAction,
}: {
  step: ChecklistStep;
  index: number;
  isOpen: boolean;
  isDone?: boolean;
  shouldOpen: () => void;
  completed: () => void;
  triggerAction: () => void;
}) {
  const configStore = useConfigStore();

  if (!step) {
    return null;
  }

  var content = "";
  try {
    if (step) {
      content = generateHTML(step.description, [
        StarterKit.configure({ codeBlock: {}, heading: false }),
        Heading.configure({
          levels: [1, 2, 3],
        }),
        Callout,
        Mention,
        Placeholder,
        CustomLink.configure({
          protocols: ["http", "https", "mailto", "tel", "gleap"],
        }),
        ServerVariableNode,
        Image,
        HelpCenterArticleExtension,
        TextStyle,
        Youtube.configure({ controls: true }),
        IFrame,
      ]);
      content = replaceAll(content, "\n", "<br>");
    }
  } catch (_) {}

  const renderAction = () => {
    if (step.action === "none" || !step.action || step.action === "") {
      return null;
    }

    return (
      <div
        className="checklist-task-body-action"
        onClick={() => {
          triggerAction();
        }}
      >
        <div className="action-button form-send-button">{step.actionTitle}</div>
      </div>
    );
  };

  const renderCompletion = () => {
    if (!step.allowMarkDone) {
      return;
    }

    return (
      <div
        className="checklist-task-body-markdone"
        onClick={() => {
          completed();
        }}
      >
        <Check className="checklist-task-body-markdone-icon" />
        <div className="checklist-task-body-markdone-label">
          {configStore.translateText("markAsDone")}
        </div>
      </div>
    );
  };

  const handleContentClick = (event: any) => {
    if (event.target.tagName === "IMG") {
      Communicator.openImage(event.target.src);
    }
  };

  return (
    <div
      className={`checklist-task ${isOpen ? "checklist-task--active" : ""} ${
        isDone ? "checklist-task--done" : ""
      }`}
    >
      <div
        className="checklist-task-header"
        onClick={() => {
          shouldOpen();
        }}
      >
        <div className="checklist-task-header--badge">
          {isDone ? <Check /> : index + 1}
        </div>
        <div className="checklist-task-header--title">{step.title}</div>
      </div>
      {isOpen && (
        <div className="checklist-task-body">
          <div
            onClick={handleContentClick}
            className="checklist-task-body-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          {renderAction()}
          {renderCompletion()}
        </div>
      )}
    </div>
  );
}

export default ChecklistTask;
