import { useState } from 'react';
import CommunicationManager from '../../Helper/CommunicationManager';
import { useAppViewStore } from '../../Stores/AppView';
import { useFormStore } from '../../Stores/Form';
import { AppMode, useRouterStore } from '../../Stores/Router';
import { ReactComponent as CheckIcon } from '../../Assets/check-sharp-solid.svg';
import './AIActionButton.scss';

const ActionButton = ({ action, index }: { action: any, index: number }) => {
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        setClicked(true);

        let data = action.data ?? {};
        let name = action.name ?? "";
        delete data.actionButtonTitle;

        if (name === 'reportabug') {
            const flow = "bugreporting";
            useFormStore.setState({
                feedbackFlow: flow,
                isSurveyFlow: false,
                preFillData: {
                    description: data?.description ?? "",
                },
            });
            useRouterStore.getState().pushPage(
                "flow",
                {
                    flowId: flow,
                }
            );
            useAppViewStore.getState().reset();

            return;
        }

        CommunicationManager.getInstance().sendMessage({
            name: "tool-execution",
            data: {
                name: name,
                params: data,
            },
        });
    };

    return (
        <button key={index} className='ai-action-button' onClick={handleClick}>
            {action?.actionButtonTitle}
            {clicked && <CheckIcon />}
        </button>
    );
};

export default ActionButton;
