import { generateText } from "@tiptap/core";
import Heading from "@tiptap/extension-heading";
import Image from "@tiptap/extension-image";
import Mention from "@tiptap/extension-mention";
import Placeholder from "@tiptap/extension-placeholder";
import TextStyle from "@tiptap/extension-text-style";
import StarterKit from "@tiptap/starter-kit";
import { ReactComponent as ArrowRight } from "../../Assets/angle-right-solid.svg";
import CustomLink from "../../Helper/CustomLink";
import { HelpCenterArticleExtension } from "../../Helper/HelpcenterExtension";
import { Article } from "../../Stores/News";
import { useRouterStore } from "../../Stores/Router";
import "./NewsCard.scss";
import { Callout } from "../../Helper/Callout";
import { ServerVariableNode } from "../../Helper/VariableExtension";

function NewsCard({ news }: { news: Article }) {
  const routerStore = useRouterStore();

  if (!news) {
    return null;
  }

  var previewText = "";
  try {
    if (news.message) {
      previewText = generateText(news.message, [
        StarterKit.configure({ codeBlock: {}, heading: false }),
        Heading.configure({
          levels: [1, 2, 3],
        }),
        Callout,
        Mention,
        Placeholder,
        ServerVariableNode,
        CustomLink.configure({
          protocols: ["http", "https", "mailto", "tel", "gleap"],
        }),
        Image,
        HelpCenterArticleExtension,
        TextStyle,
      ]);
    }
  } catch (_) {}

  return (
    <div
      className="news-card"
      onClick={() => {
        routerStore.pushPage("newsdetails", { id: news.id });
      }}
    >
      {news.coverImageUrl && news.coverImageUrl.length > 0 && (
        <img className="news-card-image" src={news.coverImageUrl} />
      )}
      <div className="news-card-content">
        <div className="news-card-content-content">
          <div className="news-card-content-title">
            {news?.config?.pinned && "📌 "}
            {news.subject}
          </div>
          <div className="news-card-content-meta">{previewText}</div>
        </div>
        <ArrowRight className="news-card-content-show-details" />
      </div>
    </div>
  );
}

export default NewsCard;
