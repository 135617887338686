import { create } from "zustand";
import { useRouterStore } from "./Router";
import { useSessionStore } from "./Session";
import { useConfigStore } from "./Config";

export type Article = {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  sentAt: Date;
  coverImageUrl: string;
  subject: string;
  message: any;
  config: {
    pinned?: boolean;
  };
  sender: {
    firstName?: string;
    lastName?: string;
    id?: string;
    profileImageUrl?: string;
  };
};

interface NewsState {
  latestArticle?: Article;
  currentArticle?: Article;
  articles: Article[];
  hasMore: boolean;
  loadingNews: boolean;
  page: number;
  setPage: (page: number) => void;
  loadNews: () => void;
  loadArticle: (id: string) => void;
}

export const useNewsStore = create<NewsState>()((set, get) => ({
  latestArticle: undefined,
  currentArticle: undefined,
  articles: [],
  page: 0,
  hasMore: true,
  loadingNews: false,
  setPage: (page: number) => set({ page }),
  loadArticle: (id: string) => {
    const sessionStore = useSessionStore.getState();
    set({
      currentArticle: undefined,
    });

    var xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      sessionStore.apiUrl +
        "/outbound/news/shared/" +
        id +
        "?lang=" +
        useConfigStore.getState().getCurrentLanguage()
    );
    if (sessionStore.session) {
      xhr.setRequestHeader("Api-Token", sessionStore.sdkKey);
      xhr.setRequestHeader("Gleap-Id", sessionStore.session.gleapId ?? "");
      xhr.setRequestHeader("Gleap-Hash", sessionStore.session.gleapHash ?? "");
    }
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200 && xhr.responseText) {
        try {
          const data = JSON.parse(xhr.responseText);
          if (data) {
            set({
              currentArticle: data,
            });
          } else {
            useRouterStore.getState().setPage("");
          }
        } catch (exp) {
          useRouterStore.getState().setPage("");
        }
      }
    };

    xhr.send();
  },
  loadNews() {
    const sessionStore = useSessionStore.getState();
    const page = get().page;

    set({ loadingNews: true });

    const gleapId = sessionStore?.session?.gleapId ?? "";
    const gleapHash = sessionStore?.session?.gleapHash ?? "";

    var xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      sessionStore.apiUrl +
        "/outbound/news/shared?gleapId=" +
        gleapId +
        "&gleapHash=" +
        gleapHash +
        "&lang=" +
        useConfigStore.getState().getCurrentLanguage() +
        "&page=" +
        page
    );
    if (sessionStore.session) {
      xhr.setRequestHeader("Api-Token", sessionStore.sdkKey);
      xhr.setRequestHeader("Gleap-Id", gleapId);
      xhr.setRequestHeader("Gleap-Hash", gleapHash);
    }
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200 && xhr.responseText) {
        try {
          const data = JSON.parse(xhr.responseText);
          if (data) {
            const hasArticles = get().articles?.length > 0;
            set({ page: page + 1 });

            set({
              articles: !hasArticles ? data : [...get().articles, ...data],
              loadingNews: false,
              hasMore: data.length === 10,
            });

            if (!hasArticles) {
              set({
                latestArticle: data.length > 0 ? data[0] : undefined,
              });
            }
          }
        } catch (exp) { }
      }
    };

    xhr.send();
  },
}));
