import { create } from "zustand";
import moment from "moment";
import { useSessionStore } from "./Session";
import { useTicketStore } from "./Ticket";

export type ConfigMenuItem = {
  title?: string;
  infoDescription?: string;
  description?: string;
  botId?: string;
  icon?: string;
  actionType?: string;
  actionBody?: string;
  color?: string;
  actionOpenInNewTab?: boolean;
  targetAudience?: string;
};

export type Config = {
  showOnlineStatus?: boolean;
  replyTime?: string;
  showSources?: boolean;
  stn?: boolean;
  sts?: boolean;
  stt?: boolean;
  showNoFeatureSuggestions?: boolean;
  bgType?: string;
  showNewsOnHome?: boolean;
  showHelpSearchOnHome?: boolean;
  bgBlur?: boolean;
  bgImage?: string;
  helpSearch?: string;
  v?: number;
  openingTimes?: any[];
  animateBG?: boolean;
  fadebg?: boolean;
  helpcenterConfig?: any;
  timezone?: string;
  helpSearchLabel?: string;
  newsMenuLabel?: string;
  checklistMenuLabel?: string;
  knowledgeBaseMenuLabel?: string;
  featureRequestsMenuLabel?: string;
  allConversationsMenuLabel?: string;
  homeLabel?: string;
  name?: string;
  color?: string;
  localizationOptions?: {
    rtl?: boolean;
    language?: string;
  };
  customCSS?: string;
  thankYouDuration?: number;
  backgroundColor?: string;
  headerColor?: string;
  buttonColor?: string;
  borderRadius?: number;
  enabFeatReqBut?: string;
  logo?: string;
  disableBGFade?: boolean;
  disableBGGradient?: boolean;
  hideLogo?: boolean;
  buttonLogo?: string;
  hideBranding?: boolean;
  showHelpCenter?: boolean;
  hideAllConversations?: boolean;
  showMsgBtnOnConvTab?: boolean;
  showConvFilters?: boolean;
  hideKnowledgeBase?: boolean;
  hideNews?: boolean;
  showHomeHelpCenter?: boolean;
  hideChecklists?: boolean;
  showNoHomeNews?: boolean;
  showNoHomeChecklist?: boolean;
  hideFeatureRequests?: boolean;
  enableConsoleLogs?: boolean;
  spamProtection?: boolean;
  enableReplays?: boolean;
  replaysInterval?: number;
  enableNetworkLogs?: boolean;
  networkLogPropsToIgnore?: string[];
  enablePrivacyPolicy?: boolean;
  enableShortcuts?: boolean;
  privacyPolicyURL?: string;
  enableMenu?: boolean;
  menuItems?: ConfigMenuItem[];
  activationMethodFeedbackButton?: boolean;
  activationMethodShake?: boolean;
  activationMethodScreenshotGesture?: boolean;
  enableCrashDetector?: boolean;
  crashDetectorIsSilent?: boolean;
  enableRageClickDetector?: boolean;
  rageClickDetectorIsSilent?: boolean;
  enableNetworkLogFilters?: boolean;
  jsMajorVersion?: string;
  enableIntercomCompatibilityMode?: boolean;
  hideWavingHandAfterName?: boolean;
  vision?: boolean;
  hideUsersName?: boolean;
  hideTeam?: boolean;
  widgetButtonText?: string;
  welcomeText?: string;
  showInfoPopup?: boolean;
  feedbackButtonPosition?: string;
  staticTranslations?: any;
  operatorAvatarImage?: string;
  operatorName?: string;
};

interface ConfigState {
  config: Config;
  team: {
    firstName: string;
    profileImageUrl: string;
    id: string;
  }[];
  homeWidgetsLoaded: boolean;
  homeWidgets: {
    type: "menu" | "app";
    config: any;
    targetAudience: "all" | "guests" | "users";
    conditions: any[];
  }[];
  aiTools: any[];
  isApp: boolean;
  overrideLanguage: string;
  loadAction: (actionId: string) => any;
  setOverrideLanguage: (overrideLanguage: string) => void;
  setConfig: (config: any) => void;
  getUIColor: () => string;
  isRTLLang: () => boolean;
  translateText: (key?: string) => string;
  translateTextWithVars: (key?: string, vars?: any) => string;
  getBotName: () => string;
  getHeaderColor: () => string;
  getHeaderColorSecondary: (key: string) => string;
  getBackgroundColor: () => string;
  loadTeamInfo: () => void;
  loadHomeWidgets: () => void;
  updateMomentLanguage: () => void;
  getCurrentLanguage: (short?: boolean) => string;
  getButtonColor: () => string;
  getBorderRadius: (multiplier?: number) => number;
  setAiTools: (aiTools: any[]) => void;
}

export const useConfigStore = create<ConfigState>()((set, get) => ({
  config: {},
  team: [],
  homeWidgets: [],
  homeWidgetsLoaded: false,
  aiTools: [],
  isApp: false,
  overrideLanguage: "",
  setAiTools: (aiTools) => {
    set({
      aiTools,
    });
  },
  loadHomeWidgets() {
    const sessionStore = useSessionStore.getState();
    set({
      homeWidgets: [],
      homeWidgetsLoaded: false,
    });

    var xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      sessionStore.apiUrl + "/config/" + sessionStore.sdkKey + "/homewidgets"
    );
    if (sessionStore.session) {
      xhr.setRequestHeader("Api-Token", sessionStore.sdkKey);
      xhr.setRequestHeader("Gleap-Id", sessionStore.session.gleapId ?? "");
      xhr.setRequestHeader("Gleap-Hash", sessionStore.session.gleapHash ?? "");
    }
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200 && xhr.responseText) {
        try {
          const data = JSON.parse(xhr.responseText);
          if (data && data.length > 0) {
            set({
              homeWidgets: data,
              homeWidgetsLoaded: true,
            });
          }
        } catch (exp) {}
      }
    };

    xhr.send();
  },
  loadTeamInfo() {
    const sessionStore = useSessionStore.getState();
    set({
      team: [],
    });

    var xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      sessionStore.apiUrl + "/config/" + sessionStore.sdkKey + "/team"
    );
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200 && xhr.responseText) {
        try {
          const data = JSON.parse(xhr.responseText);
          if (data && data.length > 0) {
            set({
              team: data,
            });
          }
        } catch (exp) {}
      }
    };

    xhr.send();
  },
  loadAction(actionId) {
    return new Promise((resolve, reject) => {
      const sessionStore = useSessionStore.getState();
      const currentLanguage = useConfigStore.getState().getCurrentLanguage();
      const url = `${sessionStore.apiUrl}/config/${sessionStore.sdkKey}/action/${actionId}?lang=${currentLanguage}`;

      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);

      xhr.onload = function () {
        if (xhr.status == 200) {
          try {
            const data = JSON.parse(xhr.responseText);
            resolve(data);
          } catch (exp) {
            reject(exp);
          }
        } else {
          reject(new Error());
        }
      };

      xhr.onerror = function () {
        reject(new Error());
      };

      xhr.send();
    });
  },
  getBotName: () => {
    const config = get().config;
    if (config?.operatorName && config?.operatorName.length > 0) {
      return config?.operatorName;
    }
    return "Kai";
  },
  translateText: (key) => {
    if (!key) {
      return "";
    }

    const {
      config: { staticTranslations },
    } = get();

    if (typeof staticTranslations === "undefined") {
      return key;
    }

    if (staticTranslations && staticTranslations[key]) {
      return staticTranslations[key];
    }

    return key;
  },
  translateTextWithVars: (key, vars) => {
    if (!key) {
      return "";
    }

    var template = get().translateText(key);
    if (!template) {
      return "";
    }

    return template.replace(/{(\w+)}/g, function (_, key) {
      return vars[key];
    });
  },
  isRTLLang: () => {
    const {
      config: { localizationOptions },
    } = get();

    return localizationOptions?.rtl || false;
  },
  getCurrentLanguage: (short = false) => {
    const overrideLanguage = get().overrideLanguage;
    var language = "en";
    if (typeof navigator.language !== "undefined") {
      language = navigator.language.toLowerCase();
    }
    if (overrideLanguage && overrideLanguage !== "") {
      language = overrideLanguage.toLowerCase();
    }
    if (short) {
      return language.substring(0, 2);
    }
    return language;
  },
  setConfig: (config: any) => {
    set({
      config,
    });
    get().updateMomentLanguage();

    if (config.showConvFilters) {
      useTicketStore.getState().setTicketFilter({ status: "OPEN" });
    } else {
      useTicketStore.getState().setTicketFilter({ status: undefined });
    }
  },
  setOverrideLanguage: (overrideLanguage: string) => {
    set({ overrideLanguage });
    get().updateMomentLanguage();
  },
  updateMomentLanguage: () => {
    moment.locale(get().getCurrentLanguage(true));
  },
  getUIColor: () => {
    return get().config.color || "#000000";
  },
  getHeaderColor: () => {
    return get().config.headerColor || "#000000";
  },
  getHeaderColorSecondary: (key: string) => {
    return (get().config as any)[key] || get().getHeaderColor();
  },
  getBackgroundColor: () => {
    return get().config.backgroundColor || "#ffffff";
  },
  getButtonColor: () => {
    return get().config.buttonColor || "#000000";
  },
  getBorderRadius: (multiplier = 1) => {
    const staticFactor = 0.8;
    return Math.round(26 * multiplier * staticFactor);
  },
}));
