import { useCallback, useEffect, useRef, useState } from "react";
import ChecklistTask from "../../Components/ChecklistTask/ChecklistTask";
import { useChecklistStore } from "../../Stores/Checklist";
import { useConfigStore } from "../../Stores/Config";
import { useRouterStore } from "../../Stores/Router";
import ReactCanvasConfetti from "react-canvas-confetti";
import "./ChecklistDetails.scss";
import CommunicationManager from "../../Helper/CommunicationManager";
import Communicator from "../../Helper/Communicator";

function ChecklistDetails() {
  const checklistStore = useChecklistStore();
  const routerStore = useRouterStore();
  const configStore = useConfigStore();
  const currentChecklist = checklistStore.currentChecklist;
  const [activeStep, setActiveStep] = useState(-1);

  const refAnimationInstance = useRef(null as any);

  const getInstance = useCallback((instance: any) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio: any, opts: any) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio)
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 50,
      startVelocity: 38
    });
  }, [makeShot]);

  useEffect(() => {
    if (routerStore.currentData && routerStore.currentData.id) {
      checklistStore.loadChecklist(routerStore.currentData.id);
    }
  }, [routerStore.currentData]);

  useEffect(() => {
    if (checklistStore.currentChecklist?.status === "done" && currentChecklist?.outbound?.config?.confetti) {
      fire();
    }
  }, [checklistStore.currentChecklist?.status]);

  useEffect(() => {
    if (currentChecklist?.step !== undefined && currentChecklist?.step !== null) {
      setActiveStep(currentChecklist.step);
    }
  }, [currentChecklist?.step]);

  const renderContent = () => {
    if (!currentChecklist?.outbound) {
      return (<>
        <div className="checklists-title">
          <div
            className="skeleton-box"
            style={{
              minWidth: "80%",
              minHeight: "18px",
            }}
          />
        </div>
        <div className="checklists-content-skeleton">
          <div
            className="skeleton-box"
            style={{
              minWidth: "68%",
              minHeight: "16px",
              marginBottom: "7px",
            }}
          />
          <div
            className="skeleton-box"
            style={{
              minWidth: "70%",
              minHeight: "16px",
              marginBottom: "7px",
            }}
          />
          <div
            className="skeleton-box"
            style={{
              minWidth: "65%",
              minHeight: "16px",
              marginBottom: "7px",
            }}
          />
        </div>
      </>);
    }

    if (checklistStore.currentChecklist?.status === "done") {
      return (<div className="checklists-content checklists-content--done">
        <div className="checklists-title" onClick={() => {
          if (currentChecklist?.outbound?.config?.confetti) {
            fire();
          }
        }}>{currentChecklist?.outbound?.config?.successTitle}</div>
        <div className="checklists-description">{currentChecklist?.outbound?.config?.successMessage}</div>
      </div>);
    }

    var checklistSteps = currentChecklist?.outbound?.config?.steps ?? [];

    let duration = 0;
    checklistSteps.forEach((step, index) => {
      if (index >= (currentChecklist?.step ?? 0) && step.duration && step.duration > 0) {
        duration += step.duration;
      }
    });

    var progress = (currentChecklist?.step ?? 0) / checklistSteps.length * 100;
    if (progress < 100) {
      progress += 3;
    }

    return (
      <>
        <div className="checklists-content">
          <div className="checklists-title">{currentChecklist?.outbound?.subject}</div>
          <div className="checklists-description">{currentChecklist?.outbound?.message}</div>
          {currentChecklist?.outbound.sender ? (
            <div className="checklists-sender">
              <img src={currentChecklist?.outbound.sender.profileImageUrl} />
              <span>
                {currentChecklist?.outbound.sender.firstName}
              </span>
            </div>
          ) : null}
          <div className="checklist-progress-labels">
            <div className="checklist-progress-label">{configStore.translateTextWithVars("taskProgress", {
              a: currentChecklist?.step ?? 0,
              b: checklistSteps.length
            })}</div>
            {duration > 0 &&
              <div className="checklist-progress-label">{configStore.translateTextWithVars("aboutMinutes", {
                a: duration,
              })}</div>}
          </div>
          <div className="checklist-progress-bar">
            <div className="checklist-progress-bar-progress" style={{
              width: `${progress}%`
            }} />
          </div>
          <div className="checklist-tasks">
            {checklistSteps.map((step, index) => {
              return <ChecklistTask
                triggerAction={() => {
                  if (step?.action === "BOT") {
                    CommunicationManager.getInstance().processAction({
                      name: 'start-bot',
                      data: {
                        botId: step?.botId,
                      }
                    });
                  } else if (step?.action === "CUSTOM_ACTION") {
                    Communicator.runCustomAction(step?.actionBody);
                    Communicator.closeWidget();
                  } else if (step?.action === "REDIRECT_URL") {
                    Communicator.openURL(step?.actionBody, step?.actionOpenInNewTab ? true : false);
                    Communicator.closeWidget();
                  } else if (step?.action === "FEEDBACK_FLOW") {
                    CommunicationManager.getInstance().processAction({
                      name: 'start-feedbackflow',
                      data: {
                        flow: step?.formId,
                      }
                    });
                  } else if (step?.action === "NEWS_ARTICLE") {
                    // TODO
                    CommunicationManager.getInstance().processAction({
                      name: 'open-news-article',
                      data: {
                        id: step?.articleId,
                      }
                    });
                  } else if (step?.action === "HELP_ARTICLE") {
                    CommunicationManager.getInstance().processAction({
                      name: 'open-help-article',
                      data: {
                        articleId: step?.articleId,
                        pushToHistory: true,
                      }
                    });
                  }
                }}
                completed={() => {
                  if (currentChecklist.id) {
                    var update: any = {
                      step: index + 1
                    };

                    // Locally update the step.
                    if (update.step >= checklistSteps.length) {
                      update["status"] = "done";
                    }

                    checklistStore.updateChecklist(currentChecklist.id, update);
                  }
                }} isDone={currentChecklist.step > index} isOpen={activeStep === index} shouldOpen={() => {
                  setActiveStep(index === activeStep ? -1 : index);
                }} step={step} index={index} key={index} />;
            })}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="checklists-details">
      {renderContent()}
      <ReactCanvasConfetti refConfetti={getInstance} style={{
        position: "fixed",
        pointerEvents: "none",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0
      }} />
    </div>
  );
}

export default ChecklistDetails;
