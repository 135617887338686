import React from 'react';
import './IconButton.scss';

function IconButton({ onClick, icon: Icon, className, unreadCount }: { onClick: () => void, icon: React.ComponentType<React.SVGProps<SVGSVGElement>>, className?: string; unreadCount?: number }) {
    return (
        <div className={`icon-button ${className}`} onClick={onClick}>
            <Icon className="icon-button-svg" />
            {unreadCount && unreadCount > 0 ? <div className="icon-button--unread">{unreadCount > 9 ? '9+' : unreadCount}</div> : null}
        </div>
    );
}

export default IconButton;
