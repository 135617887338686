import Pusher from "pusher-js";
import { create } from 'zustand';
import { handleWebSocketEvent } from "../Helper/WebSocketEventHandler";
import { WebSocketEvent } from "../Models/WebSocketEventData";
import { useSessionStore } from "./Session";

interface WebSocketState {
  pusher?: Pusher | null;
  pusherWasDisconnected: boolean;
  connect: (onReconnect: () => void) => void;
  disconnect: () => void;
}

export const useWebSocketStore = create<WebSocketState>()((set, get) => ({
  pusher: null,
  pusherWasDisconnected: false,
  connect: (onReconnect) => {
    const { session, sdkKey, apiUrl } = useSessionStore.getState();
    if (!session || !sdkKey || !apiUrl) {
      return;
    }

    // Authenticate user
    get().pusher = new Pusher("29b0a09928856b262405", {
      cluster: "eu",
      userAuthentication: {
        endpoint: `${apiUrl}/users/me/session-auth`,
        transport: "ajax",
        headers: {
          "api-token": sdkKey,
          "gleap-id": session.gleapId,
          "gleap-hash": session.gleapHash,
        },
      },
      channelAuthorization: {
        endpoint: `${apiUrl}/users/me/session-channel-auth`,
        transport: "ajax",
        headers: {
          "api-token": sdkKey,
          "gleap-id": session.gleapId,
          "gleap-hash": session.gleapHash,
        },
      },
    });

    const pusher = get().pusher;
    if (pusher) {
      pusher.connection.bind('state_change', (state: any) => {
        // Temporary failure of the Channels connection will cause: connected -> connecting -> connected
        // If an internet connection disappears: connected -> connecting -> unavailable (after ~ 30s
        if (
          state.current === 'unavailable' ||
          (state.previous === 'connected' && state.current === 'connecting')
        ) {
          set({
            pusherWasDisconnected: true,
          });
        } else if (state.current === 'connected' && get().pusherWasDisconnected) {
          set({
            pusherWasDisconnected: false,
          });

          if (onReconnect) {
            onReconnect();
          }
        }
      });
    }

    try {
      get().pusher?.signin();

      // Subscribe to channels
      const privateSessionChannel = get().pusher?.subscribe(
        `private-session-${session.gleapId}`
      );

      // Listen to events
      const eventsToSubscribe = Object.keys(WebSocketEvent);

      for (let i = 0; i < eventsToSubscribe.length; i++) {
        const event =
          WebSocketEvent[eventsToSubscribe[i] as keyof typeof WebSocketEvent];

        privateSessionChannel?.bind(event, (data: any) => {
          handleWebSocketEvent(event, data);
        });
      }
    } catch (exp) { }
  },
  disconnect: () => {
    const { session } = useSessionStore.getState();

    if (!get().pusher || !session) {
      return;
    }

    get().pusher?.disconnect();
  },
}));
