import { useRef, useState } from 'react';
import { ReactComponent as AttachmentButton } from "../../Assets/paperclip-solid.svg";
import { useTicketStore } from '../../Stores/Ticket';
import './ImageResizePicker.scss';
import FormLoading from '../FormLoading/FormLoading';
import { ReactComponent as XIcon } from "../../Assets/x-mark.svg";

const ImageResizerPicker = ({ onFileSelect, onFileRemove }: {
    onFileSelect?: ({
        url,
        name,
        type,
    }: {
        url: string;
        name: string;
        type: string;
    }) => void;
    onFileRemove?: () => void;
}) => {
    const ticketStore = useTicketStore();
    const [imageBase64, setImageBase64] = useState('');
    const [uploadingFile, setUploadingFile] = useState(false);

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }

        const reader = new FileReader();

        reader.onload = (e) => {
            setUploadingFile(true);

            const img = new Image();
            img.onload = () => {
                const maxWidth = 2000;
                const maxHeight = 768;
                let { width, height } = img;
                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }
                const canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                if (!ctx) {
                    return;
                }
                ctx.drawImage(img, 0, 0, width, height);
                const dataUrl = canvas.toDataURL(file.type);
                setImageBase64(dataUrl);

                // Upload file.
                ticketStore.uploadFile(file).then((fileUrl) => {
                    setUploadingFile(false);
                    if (onFileSelect) {
                        onFileSelect({
                            url: fileUrl,
                            name: file.name,
                            type: file.type,
                        });
                    }
                }).catch(() => {
                    setUploadingFile(false);
                });
            };

            if (e.target === null) {
                return;
            }

            img.src = e.target.result as string;
        };

        reader.readAsDataURL(file);
    };

    const handleAttachmentButtonClick = () => {
        var inputElement = document.createElement('input');
        inputElement.style.display = 'none';
        if (document.body) {
            document.body.appendChild(inputElement);
        }
        inputElement.type = 'file';
        inputElement.accept = 'image/jpeg,image/png,image/jpg,image/webp,image/webm,image/gif';
        inputElement.multiple = false;
        inputElement.addEventListener('change', arg => {
            handleFileChange(arg);

            document.body.removeChild(inputElement);
        });
        inputElement.dispatchEvent(new MouseEvent('click'));
    };

    const handleRemoveImage = () => {
        setImageBase64('');
        setUploadingFile(false);

        if (onFileRemove) {
            onFileRemove();
        }
    };

    const renderImageState = () => {
        if (uploadingFile) {
            return <FormLoading />;
        }

        if (imageBase64) {
            return (
                <div className='image-resize-picker-preview'>
                    <img src={imageBase64} alt="" />
                    <div className='remove-image-button' onClick={handleRemoveImage}>
                        <XIcon />
                    </div>
                </div>
            );
        }

        return <AttachmentButton className='attachment-btn' onClick={handleAttachmentButtonClick} />;
    };

    return (
        <div className='image-resize-picker'>
            {renderImageState()}
        </div>
    );
};

export default ImageResizerPicker;
