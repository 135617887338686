import "./LoadingText.scss";

const LoadingText = ({ text }: { text: string }) => {
  const length = text.length;
  const duration = 0.3 * (length / 6); // scale duration based on string length

  return (
    <div className="loading01">
      {text.split("").map((char, index) => (
        <span
          key={index}
          style={{
            animationDelay: `${index * 0.06}s`,
            animationDuration: `${duration}s`, // dynamically set duration
          }}
        >
          {char}
        </span>
      ))}
    </div>
  );
};

export default LoadingText;
