import { useEffect, useState } from 'react';
import { ReactComponent as ArrowRight } from "../../Assets/angle-right-solid.svg";
import { validateEmail } from '../../Stores/Form';
import { useTicketStore } from '../../Stores/Ticket';

function BotActionInputTextOld({ action }: { action: any }) {
    const ticketStore = useTicketStore();
    const [text, setText] = useState("");
    const [valid, setValid] = useState(true);

    useEffect(() => {
        const validInput = validateInput();
        setValid(validInput);
    }, [text]);

    if (!action) {
        return null;
    }

    const validateInput = () => {
        if (action.inputType === "number") {
            const val = parseInt(text);
            if (val && !isNaN(val) && (!action.minValue || val >= action.minValue) && (!action.maxValue || val <= action.maxValue)) {
                return true;
            } else {
                return false;
            }
        }

        if (action.inputType === "email") {
            return validateEmail(text);
        }

        if (action.required) {
            if (!text || text.length === 0) {
                setValid(false);
                return false;
            }
        }

        return true;
    };

    const sendInput = () => {
        const validInput = validateInput();
        setValid(validInput)
        if (!validInput) {
            return;
        }

        ticketStore.answerBotAction({
            text,
            message: text,
        });
    };

    const getInputType = () => {
        switch (action.inputType) {
            case "email":
                return "email";
            case "number":
                return "number";
            default:
                return "text";
        }
    }

    const getMinValue = () => {
        if (action.inputType === "number") {
            return action.minValue;
        }

        return undefined;
    }

    const getMaxValue = () => {
        if (action.inputType === "number") {
            return action.maxValue;
        }

        return undefined;
    }

    return (<div className='bot-action-text-input'>
        <div className='bot-action-text-input-label'>
            {action.title}
        </div>
        <div className='bot-action-text-input-innercontainer'>
            <input
                type={getInputType()}
                placeholder={action.placeholder ?? ""}
                value={action.value}
                min={getMinValue()}
                max={getMaxValue()}
                onKeyPress={(event) => {
                    if (event.key === "Enter") {
                        sendInput();
                    }
                }}
                onChange={(e) => {
                    setText(e.target.value);
                }} />
            <button
                className={`${!valid && "button--disabled"}`}
                onClick={() => {
                    sendInput();
                }}
                type="button"
            >
                <ArrowRight />
            </button>
        </div>
    </div>);
}

export default BotActionInputTextOld;