import Link from "@tiptap/extension-link";

const CustomLink = Link.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            linktype: {
                default: 'link',
                renderHTML: (attributes) => {
                    return {
                        'data-linktype': attributes.linktype,
                        class: `linktype-${attributes.linktype}`,
                    };
                },
            }
        };
    },
});


export default CustomLink;