import { create } from "zustand";
import { useAppViewStore } from "./AppView";
import { useConfigStore } from "./Config";
import { useSessionStore } from "./Session";

interface HelpCenterState {
  open: (pushToHistory: boolean) => void;
  searchHelpCenter: (searchTerm: string, pushToHistory: boolean) => void;
  openArticle: (articleId: string, pushToHistory: boolean) => void;
  openCollection: (collectionId: string, pushToHistory: boolean) => void;
  openHelpCenter: (data?: {
    collectionId?: string;
    articleId?: string;
    searchTerm?: string;
    pushToHistory: boolean;
  }) => void;
  topArticles: any[];
  loadTopArticles: () => void;
}

const checkIfLangIsAvailableInHelpCenterConfig = (lang: string) => {
  const flowConfig = useConfigStore.getState().config;
  const possibleLangs = flowConfig?.helpcenterConfig?.localization ?? [];
  for (let i = 0; i < possibleLangs.length; i++) {
    if (possibleLangs[i].language === lang) {
      return true;
    }
  }

  return false;
};

const getDefaultHelpCenterLanguage = () => {
  if (checkIfLangIsAvailableInHelpCenterConfig("en")) {
    return "en";
  }

  const flowConfig = useConfigStore.getState().config;
  const possibleLangs = flowConfig?.helpcenterConfig?.localization ?? [];
  if (possibleLangs.length > 0) {
    return possibleLangs[0].language;
  }

  return "en";
};

export const useHelpCenterStore = create<HelpCenterState>()((set, get) => ({
  topArticles: [],
  open: (pushToHistory: boolean) => {
    get().openHelpCenter({
      pushToHistory,
    });
  },
  searchHelpCenter: (searchTerm: string, pushToHistory: boolean) => {
    get().openHelpCenter({
      searchTerm,
      pushToHistory,
    });
  },
  loadTopArticles: () => {
    const sessionStore = useSessionStore.getState();
    const gleapId = sessionStore?.session?.gleapId ?? "";
    const gleapHash = sessionStore?.session?.gleapHash ?? "";

    var xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      sessionStore.apiUrl +
        "/outbound/helparticles/recommendations?gleapId=" +
        gleapId +
        "&gleapHash=" +
        gleapHash +
        "&lang=" +
        useConfigStore.getState().getCurrentLanguage()
    );
    if (sessionStore.session) {
      xhr.setRequestHeader("Api-Token", sessionStore.sdkKey);
      xhr.setRequestHeader("Gleap-Id", gleapId);
      xhr.setRequestHeader("Gleap-Hash", gleapHash);
    }
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200 && xhr.responseText) {
        try {
          const data = JSON.parse(xhr.responseText);
          if (data) {
            set({
              topArticles: data,
            });
          }
        } catch (exp) {}
      }
    };

    xhr.send();
  },
  openArticle: (articleId: string, pushToHistory: boolean) => {
    get().openHelpCenter({
      articleId,
      pushToHistory,
    });
  },
  openCollection: (collectionId: string, pushToHistory: boolean) => {
    get().openHelpCenter({
      collectionId,
      pushToHistory,
    });
  },
  openHelpCenter: (data?: {
    collectionId?: string;
    articleId?: string;
    searchTerm?: string;
    pushToHistory?: boolean;
  }) => {
    const sessionStore = useSessionStore.getState();
    const { collectionId, articleId, searchTerm } = data || {};

    var details = "";
    if (collectionId) {
      details = `/collections/${collectionId}-collection`;
    }

    if (articleId) {
      details = `/articles/${articleId}-article`;
    }

    if (searchTerm) {
      details = `/search?q=${encodeURIComponent(searchTerm ?? "")}`;
    }

    var lang = useConfigStore.getState().getCurrentLanguage();
    if (!checkIfLangIsAvailableInHelpCenterConfig(lang)) {
      // Fallback to language (short)
      lang = useConfigStore.getState().getCurrentLanguage(true);
      if (!checkIfLangIsAvailableInHelpCenterConfig(lang)) {
        // Fallback to default language
        lang = getDefaultHelpCenterLanguage();
      }
    }

    if (data?.pushToHistory) {
      return useAppViewStore
        .getState()
        .openApp(
          `https://${sessionStore.sdkKey}-app.gleap.help/${lang}${details}`,
          "Help",
          undefined,
          undefined,
          true
        );
    }

    let hasDetails = details.length > 0;

    // Push base when details are set.
    if (hasDetails) {
      useAppViewStore
        .getState()
        .openApp(
          `https://${sessionStore.sdkKey}-app.gleap.help/${lang}`,
          "Help",
          undefined,
          undefined,
          false
        );
    }

    // Push details.
    setTimeout(() => {
      useAppViewStore
        .getState()
        .openApp(
          `https://${sessionStore.sdkKey}-app.gleap.help/${lang}${details}`,
          "Help",
          undefined,
          undefined,
          hasDetails
        );
    }, 0);
  },
}));
