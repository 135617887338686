import sanitizeHtml from "sanitize-html";

export const sanitizeHtmlHelper = (dirtyHtml: string): string => {
  const options: any = {
    allowedTags: [],
    allowedAttributes: false,
    disallowedTagsMode: "recursiveEscape",
  };

  const cleanHtml = sanitizeHtml(dirtyHtml, options);

  return cleanHtml;
};
