import { useFormStore } from '../../Stores/Form';
import "./RatingFormItem.scss";

export const ratingOptions = ['😡', '😢', '😐', '😊', '😍'];

function RatingFormItem({ formItem }: { formItem: any }) {
    const formData = useFormStore(state => state.formData);
    const fieldFormData = formData[formItem.name];
    const setFormData = useFormStore(state => state.setFormData);

    const renderOption = (value: any, index: number) => {
        const onItemClick = (() => {
            setFormData(formItem.name, {
                value,
                dirty: true
            }, true);
        })
        const isActive = fieldFormData && fieldFormData.value === value;

        return (<div key={index} className={`rating-form-item-option ${isActive && 'rating-form-item-option--active'}`} onClick={onItemClick}>
            {ratingOptions[index]}
        </div>)
    };

    return (
        <div id={formItem.name} className="rating-form-item" aria-required={formItem.required}>
            <div className="rating-form-item-items">
                {[1, 2.5, 5, 7.5, 10].map((value: any, index: number) => renderOption(value, index))}
            </div>
        </div>
    );
}

export default RatingFormItem;
