import { useConfigStore } from "../../Stores/Config";
import { ReactComponent as GleapBotIcon } from '../../Assets/GleapBotIcon.svg';
import "./ChatAvatar.scss";

function ChatAvatar({
    user,
    session,
}: {
    user?: any;
    session?: any;
}) {
    const operatorAvatarImage = useConfigStore(
        (state) => state.config.operatorAvatarImage
    );

    const renderAvatarContent = () => {
        if (user) {
            if (user.profileImageUrl) {
                return {
                    aiBot: false,
                    avatar: (<img src={user.profileImageUrl} />),
                };
            }
        }

        if (operatorAvatarImage && operatorAvatarImage.length > 0) {
            return {
                aiBot: false,
                avatar: (<img src={operatorAvatarImage} />),
            };
        }

        return {
            aiBot: true,
            avatar: <GleapBotIcon />,
        };
    };

    if (session) {
        return null;
    }

    const gleapBotAvatar = renderAvatarContent();

    return <div className={`avatar ${gleapBotAvatar.aiBot && 'avatar--aibot'}`}>{gleapBotAvatar.avatar}</div>;
};

export default ChatAvatar;