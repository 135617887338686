export const getDataDescription = ({ data, maxLength = 50 }: { data: any, maxLength: number }) => {
    if (!data) {
        return "No content";
    }

    let descriptionData: any = '';
    if (data.form) {
        const formFields = Object.keys(data.form).map(e => data.form[e]);

        let description = '';

        const textFormFields: any = formFields.filter(
            (item: any) => item.type === 'textarea' || item.type === 'text',
        );
        for (let i = 0; i < textFormFields.length; i++) {
            description += textFormFields[i].value;
            description += ' • ';
        }

        // const singleChoiceFormFields: any = formFields.filter(
        //     (item: any) => item.type === 'multiplechoice',
        // );
        // for (let i = 0; i < singleChoiceFormFields.length; i++) {
        //     const formItem = singleChoiceFormFields[i];
        //     description += formItem?.choices?.find(
        //         (choice: any) => choice.id === formItem.value
        //     )?.label ?? "--";
        //     description += ' • ';
        // }

        // const multiChoiceFormFields: any = formFields.filter(
        //     (item: any) => (item.type === 'multiple-choice-multi-select' && Array.isArray(item.value)),
        // );
        // for (let i = 0; i < multiChoiceFormFields.length; i++) {
        //     const formItem = multiChoiceFormFields[i];
        //     const labels = formItem.value
        //         .map((val: string) => {
        //             const choice = formItem?.choices?.find(
        //                 (choice: any) => choice && choice?.id === val
        //             );
        //             return choice ? choice.label : null;
        //         })
        //         .filter(Boolean);

        //     description += labels.join(", ") || "--";
        //     description += ' • ';
        // }

        const otherFields: any = formFields.filter(
            (item: any) => item.type !== 'textarea' && item.type !== 'multiplechoice' && item.type !== 'multiple-choice-multi-select' && item.type !== 'text',
        );
        for (let i = 0; i < otherFields.length; i++) {
            if (otherFields[i].type === 'rating') {
                description += `${getEmojiForVal(
                    Math.round(otherFields[i].value * 10),
                )} Rating: ${formatCSATScore(otherFields[i].value)}`;
            } else {
                description += otherFields[i].value;
            }

            description += ' • ';
        }

        if (description !== '') {
            descriptionData = description.slice(0, -3);
        } else {
            descriptionData = description;
        }
    } else if (data.data && data.data.text) {
        descriptionData = data.data.text;
    }

    // Remove line breaks
    if (typeof descriptionData === 'string' || descriptionData instanceof String) {
        descriptionData = descriptionData.replace(/(\r\n|\n|\r)/gm, ' ');
    } else {
        descriptionData = '';
    }

    if (descriptionData === '') {
        return 'No content';
    }

    return truncate(descriptionData, maxLength);
};

export const truncate = (str: string, n: number) => {
    return str.length > n ? `${str.substr(0, n - 1)}...` : str;
};

export const getEmojiForVal = (val: number) => {
    if (val >= 80) {
        return '🤩';
    }
    if (val >= 60) {
        return '🙂';
    }
    if (val >= 40) {
        return '😐';
    }
    if (val >= 20) {
        return '😢';
    }
    return '😡';
};

export const formatCSATScore = (val: number) => {
    const finalVal = Math.round(val * 10);
    return `${finalVal}%`;
};

export const stripHtml = (html: any) => {
    let tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
}
