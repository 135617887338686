
import { ReactComponent as ArrowRight } from '../../Assets/angle-right-solid.svg';
import { ReactComponent as MagnifyingGlass } from '../../Assets/magnifying-glass-solid.svg';
import { useHelpCenterStore } from '../../Stores/HelpCenter';
import './HelpCenterSearch.scss';

interface HelpCenterSearchAttributes {
    articles: any[];
    label: string;
}

const HelpCenterSearch = ({
    articles = [],
    label
}: HelpCenterSearchAttributes) => {
    return (
        <div className="search-card">
            <div className="search-card-topbar" onClick={() => {
                useHelpCenterStore.getState().openHelpCenter({
                    pushToHistory: false
                });
            }}>
                <div className="search-card-topbar-title">{label}</div>
                <MagnifyingGlass className='search-card-topbar-icon' />
            </div>
            {articles && articles.length > 0 && (
                <div className="search-card-list">
                    {articles.map((article) => {
                        return (
                            <div className="search-card-list-item" key={article.id} onClick={() => {
                                useHelpCenterStore.getState().openArticle(article.id, true);
                            }}>
                                <div className='search-card-list-item-title'>{article.title}</div>
                                <ArrowRight />
                            </div>
                        );
                    })}
                </div>)}
        </div>
    );
};

export default HelpCenterSearch;