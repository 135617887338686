import { contentWithVariables } from "../../Helper/ContentReplace";
import { useFormStore } from "../../Stores/Form";
import { AppMode, useRouterStore } from '../../Stores/Router';
import { useSessionStore } from "../../Stores/Session";
import "./SurveyIntro.scss";

function SurveyIntro({ currentAction }: {
    currentAction: any;
}) {
    const routerStore = useRouterStore();
    const formStore = useFormStore();
    const session = useSessionStore((state) => state.session);
    const name = session?.name ? session?.name.split(' ')[0] : "";

    if (!((routerStore.appMode === AppMode.SURVEY_FULL || routerStore.appMode === AppMode.SURVEY_WEB) && currentAction && (currentAction.introTitle || currentAction.introMessage))) {
        return null;
    }

    if (formStore.currentStep !== 0) {
        return null;
    }

    const htmlForContent = (content: string) => {
        if (!content) {
            return "";
        }

        return contentWithVariables(content, {
            name,
        });
    }

    return (<div className="survey-intro">
        {currentAction.introTitle && <h1>{htmlForContent(currentAction.introTitle)}</h1>}
        {currentAction.introMessage && <p>{htmlForContent(currentAction.introMessage)}</p>}
    </div>);
}

export default SurveyIntro;
