import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as SendLogo } from "../../Assets/paper-plane-top-solid.svg";
import { ReactComponent as AttachmentButton } from "../../Assets/paperclip-solid.svg";
import { debounce } from "lodash";
import { useConfigStore } from "../../Stores/Config";
import "./ChatMessageComposer.scss";
import { useTicketStore } from "../../Stores/Ticket";
import FormLoading from "../FormLoading/FormLoading";

export const defaultAttachmentMessage = "📎 Attachment";

function ChatMessageComposer({
  sendMessage,
  onHeightUpdated,
  typingStatusChanged,
}: {
  sendMessage: (message: string, attachments: File[]) => void;
  onHeightUpdated: (height: number) => void;
  typingStatusChanged: (isTyping: boolean) => void;
}) {
  const config = useConfigStore();
  const uploadingAttachment = useTicketStore(
    (store) => store.uploadingAttachment
  );
  const textareaRef = useRef(null as any);
  const [currentValue, setCurrentValue] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([] as any);

  const debouncedUserTypingPing = useCallback(
    debounce(
      (text: any) => {
        var isTyping = false;

        if (text && text.length > 0) {
          isTyping = true;
        }

        typingStatusChanged(isTyping);
      },
      800,
      {
        leading: true,
        trailing: false,
      }
    ),
    []
  );

  useEffect(() => {
    if (selectedFiles && selectedFiles.length > 0) {
      internalSendMessage();
    }
  }, [selectedFiles]);

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = "0px";
      var scrollHeight = textareaRef.current.scrollHeight;
      if (scrollHeight < 50) {
        scrollHeight = 50;
      }

      textareaRef.current.style.height = scrollHeight + "px";
      onHeightUpdated(scrollHeight);
    }
  }, [currentValue]);

  const internalSendMessage = () => {
    if (
      !((currentValue && currentValue.length > 0) || selectedFiles.length > 0)
    ) {
      return;
    }

    sendMessage(
      currentValue && currentValue.length > 0
        ? currentValue
        : defaultAttachmentMessage,
      selectedFiles
    );
    setCurrentValue("");
    setSelectedFiles([]);
  };

  const handleFileInputClick = () => {
    var inputElement = document.createElement("input");
    inputElement.style.display = "none";
    if (document.body) {
      document.body.appendChild(inputElement);
    }
    inputElement.type = "file";
    inputElement.accept =
      ".json,.pdf,.csv,.txt,.xlsx,.mp4,.mp3,.mov,.webp,.webm,image/jpeg,image/png,image/jpg,image/webp,image/webm,image/gif";
    inputElement.multiple = false;
    inputElement.addEventListener("change", (arg) => {
      if (inputElement.files && inputElement.files.length > 0) {
        setSelectedFiles(Array.from(inputElement.files));
      }

      document.body.removeChild(inputElement);
    });
    inputElement.dispatchEvent(new MouseEvent("click"));
  };

  const handlePasteCapture = (e: any) => {
    if (!e.clipboardData) {
      return;
    }
    const items = e.clipboardData.items;

    if (!items || items.length === 0) {
      return;
    }

    for (let i = 0; i < items.length; i++) {
      if (items[i] && items[i].type.indexOf("image") !== -1) {
        const blob = items[i].getAsFile();
        if (blob) {
          setSelectedFiles([blob]);
        }
      }
    }
  };

  const onEnterPress = (e: any) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      internalSendMessage();
    }
  };

  const renderAttachment = () => {
    if (uploadingAttachment) {
      return (
        <div className="attachment-loading">
          <FormLoading />
        </div>
      );
    }

    return (
      <AttachmentButton
        className={`attachment-button ${
          selectedFiles &&
          selectedFiles.length > 0 &&
          "attachment-button--fileselected"
        }`}
        onClick={handleFileInputClick}
      />
    );
  };

  return (
    <>
      <div className="chat-message-composer-bg"></div>
      <div className="chat-message-composer">
        <textarea
          value={currentValue}
          placeholder={config.translateText("writeReply")}
          ref={textareaRef}
          onChange={(e) => {
            setCurrentValue(e.target.value);
            debouncedUserTypingPing(e.target.value);
          }}
          onPasteCapture={handlePasteCapture}
          onKeyDown={onEnterPress}
        />
        <div className="composer-buttons">
          {renderAttachment()}
          <SendLogo
            onClick={() => {
              internalSendMessage();
            }}
            className={`send-button ${
              !(currentValue && currentValue.length > 0) &&
              `send-button--disabled`
            } `}
          />
        </div>
      </div>
    </>
  );
}

export default ChatMessageComposer;
