import { useState } from 'react';
import { useFormStore } from '../../Stores/Form';
import { useConfigStore } from '../../Stores/Config';
import "./TextAreaFormItem.scss";
import { AppMode, useRouterStore } from '../../Stores/Router';

function TextAreaFormItem({ formItem }: { formItem: any }) {
    const configStore = useConfigStore();
    const formData = useFormStore(state => state.formData);
    const fieldFormData = formData[formItem.name];
    const minRows = 1;
    const maxRows = 8;
    const [rows, setRows] = useState(minRows + 2);
    const setFormData = useFormStore(state => state.setFormData);
    const routerStore = useRouterStore();

    var classes = "textarea-form-item-textarea";
    if (fieldFormData && fieldFormData.dirty && !fieldFormData.valid) {
        classes += " textarea-form-item-textarea--invalid";
    }

    return (
        <div className={`textarea-form-item`}>
            <textarea
                id={formItem.name}
                key={formItem.name}
                className={classes}
                value={fieldFormData ? fieldFormData.value : ""}
                name={formItem.name}
                aria-required={formItem.required}
                placeholder={formItem.placeholder}
                rows={rows}
                onChange={(e: any) => {
                    setFormData(formItem.name, {
                        value: e.target.value,
                        dirty: true
                    });

                    var style = window.getComputedStyle(e.target);
                    var lineHeight = parseInt(style.getPropertyValue('line-height').replace("px", ""));
                    if (!lineHeight || isNaN(lineHeight)) {
                        lineHeight = 19;
                    }

                    const textareaLineHeight = lineHeight;
                    const previousRows = e.target.rows;
                    e.target.rows = minRows;

                    const currentRows = ~~(e.target.scrollHeight / textareaLineHeight);

                    if (currentRows === previousRows) {
                        e.target.rows = currentRows;
                    }

                    if (routerStore.appMode === AppMode.WIDGET) {
                        return setRows(currentRows);
                    }

                    if (currentRows >= maxRows) {
                        e.target.rows = maxRows;
                        e.target.scrollTop = e.target.scrollHeight;
                    }

                    setRows(currentRows < maxRows ? currentRows : maxRows);
                }} />
        </div>
    );
}

export default TextAreaFormItem;
