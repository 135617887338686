import { useConfigStore } from '../../Stores/Config';
import { useFormStore } from '../../Stores/Form';
import "./OneToFiveFormItem.scss";

function OneToFiveFormItem({ formItem }: { formItem: any }) {
    const configStore = useConfigStore();
    const formData = useFormStore(state => state.formData);
    const fieldFormData = formData[formItem.name];
    const setFormData = useFormStore(state => state.setFormData);
    const upToNumber = !isNaN(formItem.upToNumber) ? formItem.upToNumber : 5;
    const fromNumber = !isNaN(formItem.fromNumber) ? formItem.fromNumber : 0;
    const fields = Array.from({ length: upToNumber - (fromNumber - 1) }, (_, i) => fromNumber + i);

    const renderOption = (value: any) => {
        return (
            <div 
                className={`one-to-five-form-item-item ${(fieldFormData && fieldFormData.value === value) && "one-to-five-form-item-item--active"}`} 
                key={value} 
                onClick={(() => {
                    setFormData(formItem.name, {
                        value,
                        dirty: true
                    }, true);
                })}>
                <span>{value}</span>
            </div>
        );
    };

    return (
        <div id={formItem.name} className="one-to-five-form-item" aria-required={formItem.required}>
            <div className="one-to-five-form-item-items">
                {fields.map((value: any) => renderOption(value))}
            </div>
            <div className="one-to-five-form-item-labels">
                <span>{fromNumber} - {formItem.lowestValueLabel}</span>
                <span>{upToNumber} - {formItem.highestValueLabel}</span>
            </div>
        </div>
    );
}

export default OneToFiveFormItem;
