import { useEffect } from 'react';
import { ReactComponent as TasksActive } from '../../Assets/check-done-01-active.svg';
import ChecklistCard from '../../Components/ChecklistCard/ChecklistCard';
import { useChecklistStore } from '../../Stores/Checklist';
import { useConfigStore } from "../../Stores/Config";
import "./Checklists.scss";
import { useRouterStore } from '../../Stores/Router';

function Checklists() {
    const checklistStore = useChecklistStore();
    const configStore = useConfigStore();
    const routerStore = useRouterStore();

    useEffect(() => {
        if (checklistStore.checklists && checklistStore.checklists.length === 1) {
            routerStore.setPage("checklistdetails", { id: checklistStore.checklists[0].id });
        }
    }, [checklistStore.checklists]);

    if (checklistStore.loadingChecklist) {
        return null;
    }

    return (
        <div className="checklists">
            {checklistStore.checklists.length === 0 && !checklistStore.loadingChecklist && (
                <div className="no-checklists">
                    <TasksActive className="no-checklists-yet" />
                    <span className="title">{configStore.config.checklistMenuLabel}</span>
                    <span className="description">{configStore.translateText("noChecklists")}</span>
                </div>
            )}
            {checklistStore.checklists.map((checklist) => {
                return <ChecklistCard checklist={checklist} key={checklist.id} />
            })}
        </div>
    );
}

export default Checklists;
